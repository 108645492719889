<template>
  <div class="tdgMapWrapper">
    <div id="tdgMap" ref="tdgMap" class="map"></div>

    <div v-show="page.showAddressSearchBox" id="searchBarRef" ref="searchBarRef" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header">
            <button class="modal-default-button" type="button" @click="page.showAddressSearchBox = false">
              <span class="material-icons">close</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="address-searchfield">
              <input id="address" ref="searchField" type="text" name="address" placeholder="Adresse suchen..." />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="overlayWrapper" class="overlayWrapper" :class="{ show: page.showCompanyInfoDiv }">
      <div class="overlay"></div>
      <p-overlay>
        <p-request-alert v-model="page.companyDetailDataSource.error"></p-request-alert>
        <p-button class="hideInfoBoxButton" @click="page.hideCompanyInfoBox()"></p-button>
        <div v-if="page.companyDetailDataSource.data" id="infoBoxWrapper" class="infoBoxWrapper">
          <p-progress-linear v-if="page.companyDetailDataSource.isLoading" :indeterminate="true" color="base" height="10">
          </p-progress-linear>
          <div v-if="!page.companyDetailDataSource.isLoading" class="infoBoxDetailsWrapper">
            <div class="companyDetailsWrapper">
              <div class="companyProfileDetailsWrapper">
                <h4 class="title">
                  {{ page.companyDetailDataSource.data.data.companyName }}
                </h4>
                <p-img v-if="page.companyDetailDataSource.data.data.companyLogo" :src="`data:image/jpeg;base64,${page.companyDetailDataSource.data.data.companyLogo}`" alt="Logo"></p-img>
              </div>
              <div v-if="page.getFormattedCompanyJobs().length > 0" class="companyDetailsJobsWrapper">
                <p class="subtitle">
                  {{ $t('page.map.overlay.jobs') }}:
                </p>
                <p class="jobsWrapper">
                  <ul class="jobsList">
                    <li v-for="(job, index) in page.getFormattedCompanyJobs()" :key="index">
                      {{ job }}
                    </li>
                  </ul>
                </p>
              </div>
              <div v-if="page.companyDetailDataSource.data.data.companyApprenticeshipsCount">
                <p class="apprenticeshipsWrapper">
                  {{ page.companyDetailDataSource.data.data.companyApprenticeshipsCount }} <span class="subtitle">
                    {{ $tEnum('page.map.overlay.apprenticeshipCount', page.companyDetailDataSource.data.data.companyApprenticeshipsCount > 1 ? 'multiple' : 'one') }}
                  </span>
                </p>
              </div>

              <div v-if="page.companyDetailDataSource.data.data.companyDescription" class="companyDetailsDescriptionWrapper">
                <p class="subtitle description">
                  {{ $t('page.map.overlay.companyDescription') }}:
                </p>
                <p>{{ page.companyDetailDataSource.data.data.companyDescription }}</p>
              </div>
            </div>
            <div v-if="page.companyDetailDataSource.data.data.companyAddress" class="companyAdressWrapper">
              <p class="subtitle">
                {{ $t('page.map.overlay.companyAdress') }}:
              </p>
              <p>{{ page.companyDetailDataSource.data.data.companyAddress.street }} {{ page.companyDetailDataSource.data.data.companyAddress.houseNumber }}</p>
              <p>{{ page.companyDetailDataSource.data.data.companyAddress.postalCode }} {{ page.companyDetailDataSource.data.data.companyAddress.city }}</p>
            </div>
            <div v-if="page.companyDetailDataSource.data.data.companyContactDetails" class="companyContactWrapper">
              <p class="subtitle">
                {{ $t('page.map.overlay.companyContact') }}:
              </p>
              <p>{{ page.companyDetailDataSource.data.data.companyContactDetails.contactName }}</p>
              <p v-if="page.companyDetailDataSource.data.data.companyContactDetails.phoneNumber">
                <a :href="`tel:${page.companyDetailDataSource.data.data.companyContactDetails.phoneNumber}`" class="phone-link">
                  {{ formatPhoneNumber(page.companyDetailDataSource.data.data.companyContactDetails.phoneNumber) }}
                </a>
              </p>
              <p>
                <a :href="`mailto:${page.companyDetailDataSource.data.data.companyContactDetails.emailAddress}`" class="email-link">{{ page.companyDetailDataSource.data.data.companyContactDetails.emailAddress }}</a>
              </p>
              <p v-if="page.companyDetailDataSource.data.data.companyContactDetails.website">
                <a :href="getFullUrl(page.companyDetailDataSource.data.data.companyContactDetails.website)" target="_blank" rel="noopener noreferrer" class="website-link">
                  {{ page.companyDetailDataSource.data.data.companyContactDetails.website }}
                </a>
              </p>
            </div>
            <div class="buttonWrapper">
              <p-request-alert v-if="page.error" v-model="page.error" class="companyError"></p-request-alert>
              <p-button class="addToMyTour" @click="page.companyDetailDataSource.data.data ? page.addItemToMyTour(page.companyDetailDataSource.data.data.id, page.companyDetailDataSource.data.data) : null">
                {{ $t('page.map.button.addToMyTour') }}
              </p-button>
            </div>
            <div v-if="page.companyDetailDataSource.data.data.organisationContactDetails" class="organisationWrapper">
              <div class="organisationProfileDetailsWrapper">
                <p class="subtitle organisationText">
                  {{ $t('page.map.overlay.belongsTo') }}:
                </p>
                <p class="organisationText">
                  {{ page.companyDetailDataSource.data.data.organisationName }}
                </p>
                <p-img v-if="page.companyDetailDataSource.data.data.organisationLogo" :src="`data:image/jpeg;base64,${page.companyDetailDataSource.data.data.organisationLogo}`" alt="Logo"></p-img>
              </div>
            </div>
          </div>
        </div>
      </p-overlay>
    </div>
    <div v-show="page.mapIsReady" class="myTourSideDrawerWrapper" :class="{ extended: page.showCompanyList }">
      <div class="myTourSideDrawerToggle">
        <p-button @click="toggleSideDrawer()">
          <p-icon>{{ page.showCompanyList ? 'arrow_forward_ios' : 'arrow_back_ios' }}</p-icon>
        </p-button>
      </div>
      <div class="myTourSideDrawerContent">
        <h3 class="title tourTitle">
          {{ $t('page.map.list.title') }}
        </h3>
        <div v-if="page.myTourListItems.length > 0" class="pdfButtonsWrapper">
          <p-button :disabled="page.isLoadingPDF" :loading="page.isLoadingPDF" class="downloadPdf" @click="page.downloadPdf()">
            <p-icon dense>
              download
            </p-icon>{{ $t("page.map.list.button") }}
          </p-button>
        </div>
        <div v-if="page.myTourListItems.length < 1">
          <p-card class="fill-height">
            <div class="dashboard-card">
              <p class="caption-1">
                {{ $t("page.map.list.text") }}
              </p>
            </div>
          </p-card>
        </div>
        <div class="companyListWrapper">
          <p-list-group>
            <p-list>
              <p-list-entry v-for="element in page.myTourListItems" :key="element.id">
                <p-list-group>
                  <template #activator>
                    <div class="listElements">
                      <p class="companyName">
                        {{ element.companyName }}
                      </p>
                      <div v-if="element.companyJobs" class="companyJobsWrapper">
                        <div class="jobsWrapper">
                          <ul class="unorderedJobsList">
                            <li v-for="job in element.companyJobs.split('\n')" :key="job" class="jobsList">
                              {{ job }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="companyLogoWrapper">
                      <p-img v-if="element.companyLogo" :src="`data:image/jpeg;base64,${element.companyLogo}`" alt="Logo" class="companyLogo"></p-img>
                    </div>
                    <p-button v-if="element.id" class="deleteButton" @click="page.removeItemFromMyTour(element.id)"></p-button>
                  </template>
                  <p-container class="listEntryContainer">
                    <div class="companyInfoWrapper">
                      <div v-if="element.companyApprenticeshipsCount" class="listCompanyDetailsWrapper">
                        <p>
                          {{ element.companyApprenticeshipsCount }} <span class="subtitle">
                            {{ $tEnum('page.map.overlay.apprenticeshipCount', element.companyApprenticeshipsCount > 1 ? 'multiple' : 'one') }}
                          </span>
                        </p>
                      </div>
                      <div v-if="element.companyAddress" class="listCompanyAdressWrapper">
                        <p class="subtitle">
                          {{ $t('page.map.overlay.companyAdress') }}:
                        </p>
                        <p>{{ element.companyAddress.street }} {{ element.companyAddress.houseNumber }}</p>
                        <p>{{ element.companyAddress.postalCode }} {{ element.companyAddress.city }}</p>
                      </div>
                      <div v-if="element.companyContactDetails" class="listCompanyContactWrapper">
                        <p class="subtitle">
                          {{ $t('page.map.overlay.companyContact') }}:
                        </p>
                        <p>{{ element.companyContactDetails.contactName }}</p>
                        <p>
                          <a :href="`tel:${element.companyContactDetails.phoneNumber}`" class="phone-link">
                            {{ formatPhoneNumber(element.companyContactDetails.phoneNumber) }}
                          </a>
                        </p>
                        <p>
                          <a :href="`mailto:${element.companyContactDetails.emailAddress}`" class="email-link">{{ element.companyContactDetails.emailAddress }}</a>
                        </p>
                        <p v-if="element.companyContactDetails.website">
                          <a :href="getFullUrl(element.companyContactDetails.website)" target="_blank" rel="noopener noreferrer" class="website-link">
                            {{ element.companyContactDetails.website }}
                          </a>
                        </p>
                      </div>
                    </div>
                  </p-container>
                </p-list-group>
              </p-list-entry>
            </p-list>
          </p-list-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import TdgMapPage from './map.vue.model';

  export default Vue.extend({
    name: 'TdgMap',
    layout: 'layout-widget',
    data: () => ({
      page: new TdgMapPage(),
    }),
    async mounted() {
      this.page.mapContainerElementRef = this.$refs.tdgMap as HTMLElement;
      this.page.searchFieldContainerElementRef = this.$refs.searchField as HTMLInputElement;
      this.page.searchBarRef = this.$refs.searchBarRef as HTMLElement;
      const buttons = [
        await this.createButtonControl(this.page.mapContainerElementRef as unknown as google.maps.Map, 'btnMyLocation', this.findUserPosition, 'gps_fixed'),
        await this.createButtonControl(this.page.mapContainerElementRef as unknown as google.maps.Map, 'btnSearchAddress', this.searchForAddress, 'search')];
      await this.page.initialize();
      await this.page.initializeTdgMap(buttons);
    },
    methods: {
      findUserPosition() {
        this.page.findUserPosition();
      },
      searchForAddress() {
        this.page.searchForAddress();
      },
      async createButtonControl(map : google.maps.Map, id:string, clickCall:any, icon:string) {
        const controlButton = document.createElement('button');
        controlButton.id = id;
        // Set CSS for the control.
        const span = document.createElement('i');
        span.setAttribute('role', 'img');
        span.setAttribute('class', 'p-icon material-icons');
        span.ariaLabel = icon;
        span.textContent = icon;
        controlButton.appendChild(span);
        controlButton.type = 'button';
        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener('click', clickCall);

        return controlButton;
      },
      getFullUrl(url: string) {
        // Formatierung Beispiel: www.gl-it.ch => https://www.gl-it.ch
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return `https://${url}`;
        }
        return url;
      },
      formatPhoneNumber(phoneNumber: string | undefined) {
        if (!phoneNumber) return '';
        const cleaned = phoneNumber.replace(/\D/g, '');
        // Formatierung Beispiel: +41791234567 => +41 79 123 45 67
        const formatted = cleaned.replace(/^(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})$/, '+$1 $2 $3 $4 $5');
        return formatted;
      },
      toggleSideDrawer() {
        this.page.showCompanyList = !this.page.showCompanyList;
      },
    },
  });
</script>
