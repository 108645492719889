// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetCompanyByIdRequestModel from '../../model/get-company-by-id-request-model';
import PostCompanyRequestModel from '../../model/post-company-request-model';
import GetCompaniesRequestModel from '../../model/get-companies-request-model';
import GetCompanyForUserRequestModel from '../../model/get-company-for-user-request-model';
import GetCompanyRequestModel from '../../model/get-company-request-model';
import PutCompanyRequestModel from '../../model/put-company-request-model';
import Tdg_CompanyDetailModel from '../../model/tdg-company-detail-model';
import Tdg_CompanyListPagingResultModel from '../../model/tdg-company-list-paging-result-model';
import Tdg_CompanyUpdateModel from '../../model/tdg-company-update-model';

export default (service: Servicelayer) => ({
  /**  */
  async getCompanyById(request: GetCompanyByIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetCompanyByIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-company-by-id'] ?? 'widgets/Tdg_Companies/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_CompanyDetailModel.toModel(dto));
  },
  /**  */
  async postCompany(request: PostCompanyRequestModel, config?: RequestConfig) {
    const requestDTO = PostCompanyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-post-company'] ?? 'widgets/Tdg_Companies';
    const response = await service.post<any>(endpointPath, {
      body: {
        accessToken: requestDTO.accessToken,
        acceptConditions: requestDTO.acceptConditions,
        company: requestDTO.company,
        user: requestDTO.user,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getCompanies(request: GetCompaniesRequestModel, config?: RequestConfig) {
    const requestDTO = GetCompaniesRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-companies'] ?? 'IL/Tdg_Companies';
    const response = await service.get<any>(endpointPath, {
      query: {
        selectedOrganisationId: requestDTO.selectedOrganisationId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_CompanyListPagingResultModel.toModel(dto));
  },
  /**  */
  async getCompanyForUser(request: GetCompanyForUserRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-company-for-user'] ?? 'IL/Tdg_Companies/My';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_CompanyUpdateModel.toModel(dto));
  },
  /**  */
  async getCompany(request: GetCompanyRequestModel, config?: RequestConfig) {
    const requestDTO = GetCompanyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-company'] ?? 'IL/Tdg_Companies/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_CompanyUpdateModel.toModel(dto));
  },
  /**  */
  async putCompany(request: PutCompanyRequestModel, config?: RequestConfig) {
    const requestDTO = PutCompanyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-put-company'] ?? 'IL/Tdg_Companies/{id}';
    endpointPath = endpointPath.replace(/\{id\}/g, `${requestDTO.id}`);
    const response = await service.put<any>(endpointPath, {
      body: {
        id: requestDTO.id,
        links: requestDTO.links,
        creatorId: requestDTO.creatorId,
        creatorName: requestDTO.creatorName,
        created: requestDTO.created,
        modifierId: requestDTO.modifierId,
        modifierName: requestDTO.modifierName,
        modified: requestDTO.modified,
        accessToken: requestDTO.accessToken,
        companyName: requestDTO.companyName,
        logoDocumentId: requestDTO.logoDocumentId,
        organisationId: requestDTO.organisationId,
        description: requestDTO.description,
        website: requestDTO.website,
        employeesCount: requestDTO.employeesCount,
        apprenticesCount: requestDTO.apprenticesCount,
        apprenticeShipsCount: requestDTO.apprenticeShipsCount,
        jobs: requestDTO.jobs,
        companyContactSalutation: requestDTO.companyContactSalutation,
        companyContactFirstName: requestDTO.companyContactFirstName,
        companyContactLastName: requestDTO.companyContactLastName,
        companyContactPhoneNumber: requestDTO.companyContactPhoneNumber,
        companyContactEmail: requestDTO.companyContactEmail,
        companyStreet: requestDTO.companyStreet,
        companyHouseNumber: requestDTO.companyHouseNumber,
        companyZIP: requestDTO.companyZIP,
        companyCity: requestDTO.companyCity,
        companyLocation: requestDTO.companyLocation,
        acceptConditions: requestDTO.acceptConditions,
        companyUserId: requestDTO.companyUserId,
        logoDocumentExternalId: requestDTO.logoDocumentExternalId,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
});
