import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema: IJSONSchema = {
  type: 'object',
  properties: {
    accessToken: { type: 'string' },
    isNotDefaultLocation: {
      type: 'boolean',
      enum: [true],
      title: 'Standort',
    },
    acceptConditions: {
      type: 'boolean',
      enum: [true],
    },
    company: {
      type: 'object',
      properties: {
        name: { type: 'string', minLength: 1, maxLength: 150 },
        street: { type: 'string', minLength: 1, maxLength: 150 },
        houseNumber: { type: 'string', maxLength: 10 },
        zip: { type: 'string', minLength: 1, maxLength: 10 },
        city: { type: 'string', minLength: 1, maxLength: 150 },
        website: { type: 'string', maxLength: 255 },
        description: { type: 'string', maxLength: 4000 },
        employeesCount: { type: 'number', maximum: 2147483647 },
        apprenticesCount: { type: 'number', maximum: 2147483647 },
        apprenticeShipsCount: { type: 'number', maximum: 2147483647 },
        jobs: { type: 'string', maxLength: 500 },
        contact: {
          type: 'object',
          properties: {
            salutation: { type: 'integer', minimum: 0 },
            firstName: { type: 'string', minLength: 1, maxLength: 100 },
            lastName: { type: 'string', minLength: 1, maxLength: 100 },
            phoneNumber: { type: 'string', pattern: validationPatterns.phone, maxLength: 20 },
            email: { type: 'string', pattern: validationPatterns.email, maxLength: 255 },
          },
          required: [
            'salutation', 'firstName', 'lastName', 'phoneNumber', 'email',
          ],
        },
      },
      required: [
        'name', 'street', 'zip', 'city', 'contact',
      ],
    },

    user: {
      type: 'object',
      properties: {
        email: { type: 'string', pattern: validationPatterns.email, maxLength: 255 },
        password: { type: 'string', minLength: 6, maxLength: 255 },
        repeatPassword: { type: 'string', minLength: 6, maxLength: 255 },
      },
      required: ['email', 'password', 'repeatPassword'],
    },
  },
  required: ['company', 'user', 'accessToken', 'acceptConditions', 'isNotDefaultLocation'],
};

export default personalDetailsVueSchema;
