// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ContactRequestSchema from './contact-request-schema';

const schema = {
  $id: '/organisation-request-schema',
  type: 'object',
  required: ['name', 'membersCount', 'street', 'zip', 'city'],
  properties: {
    name: {
      type: 'string',
      minLength: 1,
    },
    logoDocumentExternalId: {
      type: 'string',
      nullable: true,
    },
    membersCount: {
      type: 'integer',
    },
    street: {
      type: 'string',
      minLength: 1,
    },
    houseNumber: {
      type: 'string',
      nullable: true,
    },
    zip: {
      type: 'string',
      minLength: 1,
    },
    city: {
      type: 'string',
      minLength: 1,
    },
    isActive: {
      type: 'boolean',
      nullable: true,
    },
    isSupporter: {
      type: 'boolean',
      nullable: true,
    },
    contact: {
      ...ContactRequestSchema,
    },
  },
} as IJSONSchema;

export default schema;
