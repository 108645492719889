import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema = {
  ...queryBuilder.properties({
    organisationName: { type: 'string', required: true, maxLength: 150 },
    membersCount: { type: 'number', required: true, maximum: 2147483647 },
    organisationContactSalutation: { type: 'integer', required: true },
    organisationContactFirstName: { type: 'string', required: true, maxLength: 100 },
    organisationContactLastName: { type: 'string', required: true, maxLength: 100 },
    organisationContactPhoneNumber: {
      type: 'string', pattern: validationPatterns.phone, required: true, maxLength: 20,
    },
    organisationContactEmail: {
      type: 'string', pattern: validationPatterns.email, required: true, maxLength: 255,
    },
    organisationStreet: { type: 'string', required: true, maxLength: 150 },
    organisationHouseNumber: { type: 'string', maxLength: 10 },
    organisationZIP: {
      type: 'string', required: true, minLength: 1, maxLength: 10,
    },
    organisationCity: { type: 'string', required: true, maxLength: 150 },
  }),
};

export default personalDetailsVueSchema;
