<template>
  <div v-if="page.combinedModels" class="registrationWrapper company">
    <p-form v-if="!page.registrationSuccess" :model="page.combinedModels" class="registrationForm form">
      <div class="registrierungsCodeWrapper">
        <p-text-field v-model="page.combinedModels.accessToken" name="accessToken" :label="$t('page.createCompany.label.externalId')" required></p-text-field>
      </div>
      <div class="firmenDetailsWrapper">
        <h3 class="title">
          {{ $t('page.createCompany.title.companyAdress') }}
        </h3>
        <p-text-field v-model="page.combinedModels.company.name" :label="$t('page.createCompany.label.companyName')" required></p-text-field>
        <p-row class="streetWrapper">
          <p-col class="street">
            <p-text-field v-model="page.combinedModels.company.street" :label="$t('page.createCompany.label.companyStreet')" required></p-text-field>
          </p-col>
          <p-col class="houseNumber">
            <p-text-field v-model="page.combinedModels.company.houseNumber" :label="$t('page.createCompany.label.companyHouseNumber')"></p-text-field>
          </p-col>
        </p-row>
        <p-row class="cityWrapper">
          <p-col class="zip">
            <p-text-field v-model="page.combinedModels.company.zip" :label="$t('page.createCompany.label.companyZIP')" required></p-text-field>
          </p-col>
          <p-col class="city">
            <p-text-field v-model="page.combinedModels.company.city" :label="$t('page.createCompany.label.companyCity')" required></p-text-field><br>
          </p-col>
        </p-row>
        <p-file-drop
          v-model="page.logoDocuments"
          class="logo file image"
          :label="$t('page.createCompany.label.LogoDocumentId')"
          max="7"
          accept="image/*"
          single
        ></p-file-drop>
        <p-text-field v-model="page.combinedModels.company.website" :label="$t('page.createCompany.label.website')"></p-text-field>
        <p-textarea v-model="page.combinedModels.company.description" :label="$t('page.createCompany.label.description')"></p-textarea>
        <p-number-field v-model="page.combinedModels.company.employeesCount" :label="$t('page.createCompany.label.employeesCount')"></p-number-field>
        <p-number-field v-model="page.combinedModels.company.apprenticesCount" :label="$t('page.createCompany.label.apprenticesCount')"></p-number-field>
        <p-number-field v-model="page.combinedModels.company.apprenticeShipsCount" :label="$t('page.createCompany.label.apprenticeShipsCount')"></p-number-field>
        <p-textarea v-model="page.combinedModels.company.jobs" :messages="$t('page.createCompany.label.jobs.message')" :label="$t('page.createCompany.label.jobs')"></p-textarea>
        <p-location
          :class="{ invalid: page.combinedModels.isDefaultLocation }"
          :set-marker="!page.combinedModels.isDefaultLocation"
          :default-latitude="page.combinedModels.defaultLatitude"
          :default-longitude="page.combinedModels.defaultLongitude"
          @latitudeChange="$set(page.combinedModels.company.location, 'latitude', $event)"
          @longitudeChange="$set(page.combinedModels.company.location, 'longitude', $event)"
        ></p-location>
      </div>
      <div class="kontaktDetailsWrapper">
        <h3 class="title">
          {{ $t('page.createCompany.titel.companyContactDetails') }}
        </h3>
        <p-select v-model="page.combinedModels.company.contact.salutation" :label="$t('page.createCompany.label.companyContactSalutation')" :items="page.genderOptions()" required></p-select>
        <p-text-field v-model="page.combinedModels.company.contact.firstName" :label="$t('page.createCompany.label.companyContactFirstName')" required></p-text-field>
        <p-text-field v-model="page.combinedModels.company.contact.lastName" :label="$t('page.createCompany.label.companyContactLastName')" required></p-text-field>
        <p-phone-field v-model="page.combinedModels.company.contact.phoneNumber" :label="$t('page.createCompany.label.companyContactPhoneNumber')" required></p-phone-field>
        <p-text-field v-model="page.combinedModels.company.contact.email" :label="$t('page.createCompany.label.companyContactEmail')" required type="email"></p-text-field>
      </div>
      <div class="loginWrapper">
        <h3 class="title">
          {{ $t('page.widget.title.createNewUser') }}
        </h3>
        <p-text-field v-model="page.combinedModels.user.email" :placeholder="page.combinedModels.company.contact.email" :label="$t('page.widget.label.userEmail')" required></p-text-field>
        <p-text-field v-model="page.combinedModels.user.password" :label="$t('page.widget.label.userPassword')" required type="password"></p-text-field>
        <p-text-field v-model="page.combinedModels.user.repeatPassword" :label="$t('page.widget.label.userSecondPassword')" type="password" required></p-text-field>
      </div>
      <div class="checkboxenWrapper">
        <p-checkbox v-model="page.combinedModels.acceptConditions" :label="$tEnum('page.createCompany.label.acceptConditions', 'no_html')" required>
          <span v-html="$tEnum('page.createCompany.label.acceptConditions', 'html')"></span>
        </p-checkbox>
      </div>
    </p-form>
    <p-row v-if="!page.registrationSuccess" class="saveButton">
      <p-button :disabled="page.isLoading" :loading="page.isLoading" @click="page.saveNewCompanies()">
        {{ $t('core.app.save') }}
      </p-button>
    </p-row>
    <p-request-alert v-if="!page.registrationSuccess" v-model="page.convertedError"></p-request-alert>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import CompanyRegistrationPage from './registration.vue.model';

  export default Vue.extend({
    name: 'CompanyRegistration',
    layout: 'layout-widget',
    data: () => ({
      page: new CompanyRegistrationPage(),
    }),
    beforeMount() {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('accessToken');
      if (accessToken) {
        this.page.combinedModels!.accessToken = accessToken;
      }
    },
    mounted() {
      this.page.initialize();
    },
  });
</script>
