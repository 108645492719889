<template>
  <p-container>
    <!-- Title -->
    <h2 class="mb-4">
      {{ $t('navigation.page.organisations.title') }}
    </h2>
    <p-row>
      <!-- Search Field -->
      <p-col xs12 md6 lg2>
        <p-text-field v-model="page.organisationsDataSource.filter.searchCriteria" clearable :label="$t('core.app.search')" context-icon="search"></p-text-field>
      </p-col>
      <!--
      <p-col xs12 md6 lg2>
        <p-select v-model="page.organisationsDataSource.filter.isActive" clearable :label="$t('page.organisations.select.status')" :items="items"></p-select>
      </p-col>
      -->
    </p-row>
    <p-list-view ref="organisationList" v-model="page.organisationsList" :pagination.sync="page.organisationsDataSource.paging" :columns="page.organisationColumns" :loading="page.organisationsDataSource.isLoading">
      <template slot="table-actions">
        <!-- Refresh Button -->
        <p-button :disabled="isLoading" @click="page.refreshOrganisations()">
          <p-icon>refresh</p-icon>
        </p-button>
      </template>
      <!-- Edit Organisation Button -->
      <template #actions="{ row }">
        <p-col xs12 sm6 md10>
          <p-row dense>
            <p-col xs6>
              <p-button :title="$t('page.organisations.button.copyAccessToken')" @click="page.copyAccessToken(row)">
                <p-icon>content_copy</p-icon>
              </p-button>
            </p-col>
            <p-col xs6>
              <p-button @click="page.gotoDetail(row.values.id)">
                <p-icon>edit</p-icon>
              </p-button>
            </p-col>
          </p-row>
        </p-col>
      </template>
    </p-list-view>
  </p-container>
</template>

<script lang="ts">
  import Vue from 'vue';
  import OrganisationsPage from './organisations.vue.model';

  export default Vue.extend({
    role: ['Admin', 'Hostadmin'],
    auth: true,
    data: () => ({
      page: new OrganisationsPage(),
      isLoading: false,
      selectedStatus: null as number | null,
      items: [
        { id: true, caption: 'Aktiv' },
        { id: false, caption: 'Inaktiv' },
      ],
    }),
    beforeMount() {
      this.page.organisationsDataSource.paging.currentPage = this.page.organisationsDataSource.paging.currentPage ?? 1;
      this.page.organisationsDataSource.paging.pageSize = this.page.organisationsDataSource.paging.pageSize ?? 10;
    },
    mounted() {
      this.page.initialize();
    },
  });
</script>
