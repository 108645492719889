import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import _ from '@glittr/frontend-core/src/utils';
import { ConfigType } from '@core/plugins/config/config';
import personalDetailsVueSchema from './registration.vue.schema';
import PostCompanyRequestModel from '../../../../services/v2/model/post-company-request-model';
import CompanyRequestModel from '../../../../services/v2/model/company-request-model';
import ContactRequestModel from '../../../../services/v2/model/contact-request-model';
import UserRequestModel from '../../../../services/v2/model/user-request-model';
import UploadTdgDocumentRequestModel from '../../../../services/v2/model/upload-tdg-document-request-model';
import LocationModel from '../../../../services/v2/model/location-model';

export default class CompanyRegistrationPage extends PageBase {
  constructor() {
    super();
    this.combinedModels = new PostCompanyRequestModel();
    this.combinedModels!.company = new CompanyRequestModel();
    this.combinedModels!.company!.location = new LocationModel();
    this.combinedModels!.company!.location!.latitude = this.combinedModels.defaultLatitude;
    this.combinedModels!.company!.location!.longitude = this.combinedModels.defaultLongitude;
    this.combinedModels!.company.contact = new ContactRequestModel();
    this.combinedModels!.user = new UserRequestModel();
  }

  public set convertedError(x:any) { /* NOP */ }

  public get convertedError(): any {
    if (_.isObject(this.error)) {
      return {
        ...this.error,
        data: {
          ...this.error.data,
          result: this.error.data.Result,
          info: {
            ...this.error.data.info,
            statusCode: this.error.data.Info?.StatusCode,
            statusMessage: this.error.data.Info?.StatusMessage,
            pagingInfo: this.error.data.Info?.PagingInfo,
            isError: this.error.data.Info?.IsError,
            errorInfo: {
              ...this.error.data.Info?.ErrorInfo,
              details: { ...this.error.data.Info?.ErrorInfo.Details },

            },
          },
        },
      };
    }
    return this.error;
  }

  public error?: any = undefined;

  public emailError: string | null = null;

  public logoDocuments: undefined | File[];

  public isLoading = false;

  public secondPassword: string | undefined;

  public combinedModels:PostCompanyRequestModel | undefined;

  public registrationSuccess = false;

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  public companiesDataSource = new DataSource({
    insertCommand: Vue.$service.v2.api.tdg_Companies.postCompany,
  });

  async initialize(): Promise<void> {
    // Not used
  }

  public saveNewCompanies = async () => {
    if (this.combinedModels!.user!.email == null) {
      this.combinedModels!.user!.email = this.combinedModels!.company!.contact!.email;
    }
    this.combinedModels!.user!.email = this.combinedModels!.user!.email;
    if (this.combinedModels!.isDefaultLocation) {
      this.combinedModels!.company!.location!.latitude = undefined;
      this.combinedModels!.company!.location!.longitude = undefined;
    }

    const valid = this.combinedModels!.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      const logoFiles = this.logoDocuments ?? [];
      if (logoFiles.length > 0) {
        const request = new UploadTdgDocumentRequestModel();
        request.uploadFile = this.logoDocuments?.[0];
        const documentId = await Vue.$service.v2.api.tdg_Documents.uploadTdgDocument(request);
        this.combinedModels!.company!.logoDocumentExternalId = documentId.data;
      }
      if (!this.combinedModels!.user || this.combinedModels!.user.repeatPassword !== this.combinedModels!.user.password) {
        this.error = Vue.$t('page.widget.error.passwordsDoNotMatch');
        return;
      }
      this.isLoading = true;
      try {
        await this.companiesDataSource.insert(this.combinedModels!);
        this.registrationSuccess = true;
        this.showExternalElement('widget-successelementid');
        this.isLoading = false;
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
        this.isLoading = false;
      }
    }
  };

  public getExternalElement(configKey: keyof ConfigType) {
    const externalElementId = Vue.$config.getQueryOrValue(configKey);
    if (!_.isEmpty(externalElementId)) {
      const externalElement = document.getElementById(externalElementId!);
      if (externalElement === null) {
        Vue.$log.warning(`Element with id '${externalElementId}' not found.`);
      } else {
        return externalElement;
      }
    }
    return undefined;
  }

  public showExternalElement(configKey: keyof ConfigType) {
    const elem = this.getExternalElement(configKey);
    elem?.classList.add('glitWidgetElementVisible');
  }

  public hideExternalElement(configKey: keyof ConfigType) {
    const elem = this.getExternalElement(configKey);
    elem?.classList.remove('glitWidgetElementVisible');
  }
}
