import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema: IJSONSchema = {
  type: 'object',
  properties: {
    acceptConditions: {
      type: 'boolean',
      enum: [true],
    },
    organisation: {
      type: 'object',
      properties: {
        name: { type: 'string', minLength: 1, maxLength: 150 },
        membersCount: { type: 'number', minimum: 1, maximum: 2147483647 },
        street: { type: 'string', minLength: 1, maxLength: 150 },
        houseNumber: { type: 'string', maxLength: 10 },
        zip: {
          type: 'string', minLength: 1, maxLength: 10,
        },
        city: { type: 'string', minLength: 1, maxLength: 150 },
        contact: {
          type: 'object',
          properties: {
            salutation: { type: 'integer', minimum: 0 },
            firstName: { type: 'string', minLength: 1, maxLength: 100 },
            lastName: { type: 'string', minLength: 1, maxLength: 100 },
            phoneNumber: { type: 'string', pattern: validationPatterns.phone, maxLength: 20 },
            email: { type: 'string', pattern: validationPatterns.email, maxLength: 255 },
          },
          required: [
            'salutation', 'firstName', 'lastName', 'phoneNumber', 'email',
          ],
        },
      },
      required: ['name', 'membersCount', 'street', 'zip', 'city'],
    },
    user: {
      type: 'object',
      properties: {
        email: { type: 'string', pattern: validationPatterns.email, maxLength: 255 },
        password: { type: 'string', minLength: 6, maxLength: 255 },
        repeatPassword: { type: 'string', minLength: 6, maxLength: 255 },
      },
      required: ['email', 'password', 'repeatPassword'],
    },
  },
  required: ['organisation', 'user', 'acceptConditions'],
};

export default personalDetailsVueSchema;
