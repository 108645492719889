import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class CompaniesPage extends PageBase {
  public get companiesList() {
    return this.companiesDataSource.data?.items.map((i) => { return { ...i.data }; }) || [];
  }

  public set companiesList(value) { /* Do nothing */ }

  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanies,
  });

  public organisationNameItems: Array<{ id: string; caption: string }> = [];

  async initialize(): Promise<void> {
    await this.companiesDataSource.select();
  }

  public companyColumns = [
    { value: Vue.$t('page.companies.table.id'), textAlign: 'left', field: 'id' },
    { value: Vue.$t('page.companies.table.companyName'), textAlign: 'left', field: 'companyName' },
    { value: Vue.$t('page.companies.table.organisationName'), textAlign: 'left', field: 'organisationName' },
    { value: Vue.$t('page.companies.table.companyContactFirstName'), textAlign: 'left', field: 'companyContactFirstName' },
    { value: Vue.$t('page.companies.table.companyContactLastName'), textAlign: 'left', field: 'companyContactLastName' },
    { value: Vue.$t('page.companies.table.companyContactPhoneNumber'), textAlign: 'left', field: 'companyContactPhoneNumber' },
    { value: Vue.$t('page.companies.table.companyContactEmail'), textAlign: 'left', field: 'companyContactEmail' },
    { value: '', field: 'actions' },
  ];

  gotoDetail(id: string | number) {
    Vue.$router.push(`/_admin/companies/edit/${id}`);
  }

  async refreshCompanies() {
    try {
      await this.companiesDataSource.select();
    } catch (error) {
      Vue.$log.error(`Error refreshing companies: ${error}`);
    }
  }
}
