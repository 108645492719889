import PostCompanyRequestModelBase from '../_generated/modelBases/post-company-request-model-base';

export default class PostCompanyRequestModel extends PostCompanyRequestModelBase {
  // For instructions on how to use Models visit:
  // https://glittrsoftware.visualstudio.com/glittr.core/_wiki/wikis/glittr.core.wiki/429/ServiceModels-DTOs

  public defaultLatitude = 47.1177271111;

  public defaultLongitude = 9.1492561111;

  get isDefaultLocation(): boolean {
    return (this.company?.location?.latitude || this.defaultLatitude) === this.defaultLatitude
      && (this.company?.location?.longitude || this.defaultLongitude) === this.defaultLongitude;
  }

  get isNotDefaultLocation():boolean { return !this.isDefaultLocation; }
}
