import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAnalyticsForMyOrganisationRequestModel from '../../../../../services/v2/model/get-analytics-for-my-organisation-request-model';

export default class OrganisationAnalyticsPage extends PageBase {
  public adminMode: boolean = false;

  public get dataSource() { return (this.organisationAnalytics); }

  public set dataSource(value) { /* Do nothing */ }

  public get organisationsAnalyticsList() {
    return this.organisationAnalytics.data?.items.map((i) => {
      return {
        companyName: i.data.companyName,
        companyId: i.data.companyId,
        countShowDetails: i.data.analytics?.countShowDetails,
        countAddToTour: i.data.analytics?.countAddToTour,
        countRemoveFromTour: i.data.analytics?.countRemoveFromTour,
        countDownloadTour: i.data.analytics?.countDownloadTour,
      };
    }) || [];
  }

  public set organisationsAnalyticsList(value) { /* Do nothing */ }

  public organisationAnalytics = new DataSource({
    selectCommand: async (request: GetAnalyticsForMyOrganisationRequestModel, config?: RequestConfig) => {
      try {
        return await Vue.$service.v2.api.tdg_Analytics.getAnalyticsForMyOrganisation(request, config);
      } catch (err:any) {
        if (err && (err?.data?.result?.status === 404 || err?.status === 403)) {
          Vue.$router.push('/tourdegwaerb/unknownpage');
          return undefined;
        }
        throw new Error(`${err?.message}`);
      }
    },
  });

  async initialize(): Promise<void> {
    await this.organisationAnalytics.select();
  }

  public companyColumns = [
    { value: Vue.$t('page.dashboard.organisationAnalytics.table.companyName'), field: 'companyName' },
    { value: Vue.$t('page.dashboard.organisationAnalytics.table.viewed'), textAlign: 'center', field: 'countShowDetails' },
    { value: Vue.$t('page.dashboard.organisationAnalytics.table.tourAdded'), textAlign: 'center', field: 'countAddToTour' },
    { value: Vue.$t('page.dashboard.organisationAnalytics.table.tourRemoved'), textAlign: 'center', field: 'countRemoveFromTour' },
    { value: Vue.$t('page.dashboard.organisationAnalytics.table.tourDownloaded'), textAlign: 'center', field: 'countDownloadTour' },
    { value: '', field: 'actions' },
  ];

  gotoDetail(id: string | number) {
    Vue.$router.push(`/tourdegwaerb/company/analytics/${id}`);
  }
}
