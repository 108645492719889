// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import GetAnalyticsForCompanyRequestModel from '../../model/get-analytics-for-company-request-model';
import GetAnalyticsForCompanyRequestDTO from '../dto/get-analytics-for-company-request-dto';
import dtoSchema from '../schemas/get-analytics-for-company-request-schema';

export default abstract class GetAnalyticsForCompanyRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<GetAnalyticsForCompanyRequestDTO>) {
    super();
    this.paging = {};
    if (data) {
      this.paging = {
        currentPage: data['paging.CurrentPage'],
        pageSize: data['paging.PageSize'],
        includeCount: data['paging.IncludeCount'],
        noPaging: data['paging.NoPaging'],
      };
      this.companyId = data.companyId;
      this.selectedOrganisationId = data.selectedOrganisationId;
      this.selectedCompanyId = data.selectedCompanyId;
      this.id = data.id;
      this.key = data.key;
      this.searchCriteria = data.searchCriteria;
    }
  }

  paging: {
    /**
    * @type {int32}
    */
    currentPage?: number;
    /**
    * @type {int32}
    */
    pageSize?: number;
    /**
    */
    includeCount?: boolean;
    /**
    */
    noPaging?: boolean;
  };

  /**
  * @type {int64}
  */
  companyId?: number;
  /**
  * @type {int64}
  */
  selectedOrganisationId?: number;
  /**
  * @type {int64}
  */
  selectedCompanyId?: number;
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  key?: string;
  /**
  */
  searchCriteria?: string;

  static toModel(dto: DeepPartial<GetAnalyticsForCompanyRequestDTO>): GetAnalyticsForCompanyRequestModel;
  static toModel(dto: DeepPartial<GetAnalyticsForCompanyRequestDTO> | undefined | null): GetAnalyticsForCompanyRequestModel | undefined;
  static toModel(dto: DeepPartial<GetAnalyticsForCompanyRequestDTO> | undefined | null): GetAnalyticsForCompanyRequestModel | undefined {
    return dto ? new GetAnalyticsForCompanyRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<GetAnalyticsForCompanyRequestModel> | GetAnalyticsForCompanyRequestModel): GetAnalyticsForCompanyRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      'paging.CurrentPage': unwrapped.paging?.currentPage,
      'paging.PageSize': unwrapped.paging?.pageSize,
      'paging.IncludeCount': unwrapped.paging?.includeCount,
      'paging.NoPaging': unwrapped.paging?.noPaging,
      companyId: unwrapped.companyId,
      selectedOrganisationId: unwrapped.selectedOrganisationId,
      selectedCompanyId: unwrapped.selectedCompanyId,
      id: unwrapped.id,
      key: unwrapped.key,
      searchCriteria: unwrapped.searchCriteria,
    } as GetAnalyticsForCompanyRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for GetAnalyticsForCompanyRequestModel, can not map to GetAnalyticsForCompanyRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
