// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import ResourceLinkSchema from './resource-link-schema';

const schema = {
  $id: '/put-organisation-request-schema',
  type: 'object',
  required: ['id', 'creatorId', 'externalId', 'organisationName', 'membersCount', 'organisationContactSalutation', 'organisationContactFirstName', 'organisationContactLastName', 'organisationContactPhoneNumber', 'organisationContactEmail', 'organisationStreet', 'organisationZIP', 'organisationCity', 'acceptConditions', 'id'],
  properties: {
    id: {
      type: 'integer',
    },
    links: {
      type: 'array',
      nullable: true,
      items: { ...ResourceLinkSchema },
    },
    creatorId: {
      type: 'integer',
    },
    creatorName: {
      type: 'string',
      nullable: true,
    },
    created: {
      type: 'string',
      format: 'date-time',
    },
    modifierId: {
      type: 'integer',
      nullable: true,
    },
    modifierName: {
      type: 'string',
      nullable: true,
    },
    modified: {
      type: 'string',
      format: 'date-time',
      nullable: true,
    },
    externalId: {
      type: 'string',
      format: 'uuid',
    },
    organisationName: {
      type: 'string',
      minLength: 1,
    },
    logoDocumentId: {
      type: 'integer',
      nullable: true,
    },
    membersCount: {
      type: 'integer',
    },
    organisationContactSalutation: {
      enum: [
        0,
        1,
        2,
      ],
    },
    organisationContactFirstName: {
      type: 'string',
      minLength: 1,
    },
    organisationContactLastName: {
      type: 'string',
      minLength: 1,
    },
    organisationContactPhoneNumber: {
      type: 'string',
      minLength: 1,
    },
    organisationContactEmail: {
      type: 'string',
      minLength: 1,
    },
    organisationStreet: {
      type: 'string',
      minLength: 1,
    },
    organisationHouseNumber: {
      type: 'string',
      nullable: true,
    },
    organisationZIP: {
      type: 'string',
      minLength: 1,
    },
    organisationCity: {
      type: 'string',
      minLength: 1,
    },
    acceptConditions: {
      type: 'boolean',
    },
    organisationUserId: {
      type: 'integer',
      nullable: true,
    },
    isSupporter: {
      type: 'boolean',
    },
    logoDocumentExternalId: {
      type: 'string',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
