// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AnalyticsAdminItemModel from '../../model/analytics-admin-item-model';
import AnalyticsAdminItemDTO from '../dto/analytics-admin-item-dto';
import dtoSchema from '../schemas/analytics-admin-item-schema';
import AnalyticsItemModel from '../../model/analytics-item-model';

export default abstract class AnalyticsAdminItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AnalyticsAdminItemDTO>) {
    super();
    if (data) {
      this.companyId = data.companyId ?? undefined;
      this.companyName = data.companyName ?? undefined;
      this.analytics = data.analytics ? AnalyticsItemModel.toModel(data.analytics) : undefined;
      this.organisationId = data.organisationId ?? undefined;
    }
  }
  /**
  * @type {int64}
  */
  companyId?: number;
  /**
  */
  companyName?: string;
  /**
  */
  analytics?: AnalyticsItemModel;
  /**
  * @type {int64}
  */
  organisationId?: number;

  static toModel(dto: DeepPartial<AnalyticsAdminItemDTO>): AnalyticsAdminItemModel;
  static toModel(dto: DeepPartial<AnalyticsAdminItemDTO> | undefined | null): AnalyticsAdminItemModel | undefined;
  static toModel(dto: DeepPartial<AnalyticsAdminItemDTO> | undefined | null): AnalyticsAdminItemModel | undefined {
    return dto ? new AnalyticsAdminItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AnalyticsAdminItemModel> | AnalyticsAdminItemModel): AnalyticsAdminItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      companyId: unwrapped.companyId,
      companyName: unwrapped.companyName,
      analytics: unwrapped.analytics,
      organisationId: unwrapped.organisationId,
    } as AnalyticsAdminItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AnalyticsAdminItemModel, can not map to AnalyticsAdminItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
