<template>
  <span class="language-selection">
    <a :class="{ 'hover-effect': true, 'active-language': currentLanguage == 'de' }" @click="setLanguage('de')">DE</a>
    <!-- <a :class="{ 'hover-effect': true, 'active-language': currentLanguage == 'en' }" @click="setLanguage('en')">EN</a> |
    <a :class="{ 'hover-effect': true, 'active-language': currentLanguage == 'de' }" @click="setLanguage('de')">DE</a> |
    <a :class="{ 'hover-effect': true, 'active-language': currentLanguage == 'it' }" @click="setLanguage('it')">IT</a> |
    <a :class="{ 'hover-effect': true, 'active-language': currentLanguage == 'fr' }" @click="setLanguage('fr')">FR</a> -->
  </span>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    computed: {
      currentLanguage(): string {
        return this.$translation.get();
      },
    },
    methods: {
      setLanguage(languageCode: string) {
        this.$translation.set(languageCode);
      },
    },
  });
</script>

<style lang="scss">
  .language-selection{
    user-select: none;
    .active-language {
      &:hover{
        cursor: default !important;
      }
      font-weight: bold;
    }
  }
</style>
