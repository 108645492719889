// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import PostAnalyticsRequestModel from '../../model/post-analytics-request-model';
import GetAnalyticsForMyCompanyRequestModel from '../../model/get-analytics-for-my-company-request-model';
import GetAnalyticsForCompanyRequestModel from '../../model/get-analytics-for-company-request-model';
import GetAnalyticsForMyOrganisationRequestModel from '../../model/get-analytics-for-my-organisation-request-model';
import GetAnalyticsForOrganisationRequestModel from '../../model/get-analytics-for-organisation-request-model';
import GetAnalyticsForAdminRequestModel from '../../model/get-analytics-for-admin-request-model';
import AnalyticsCompanyItemPagingResultModel from '../../model/analytics-company-item-paging-result-model';
import AnalyticsOrganisationItemPagingResultModel from '../../model/analytics-organisation-item-paging-result-model';
import AnalyticsAdminItemPagingResultModel from '../../model/analytics-admin-item-paging-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async postAnalytics(request: PostAnalyticsRequestModel, config?: RequestConfig) {
    const requestDTO = PostAnalyticsRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-post-analytics'] ?? 'widgets/Tdg_Analytics';
    const response = await service.post<any>(endpointPath, {
      body: {
        companyId: requestDTO.companyId,
        analyticEventTypeId: requestDTO.analyticEventTypeId,
        clientIdentifier: requestDTO.clientIdentifier,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as number);
  },
  /**  */
  async getAnalyticsForMyCompany(request: GetAnalyticsForMyCompanyRequestModel, config?: RequestConfig) {
    const requestDTO = GetAnalyticsForMyCompanyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-analytics-for-my-company'] ?? 'IL/Tdg_Analytics/company';
    const response = await service.get<any>(endpointPath, {
      query: {
        selectedOrganisationId: requestDTO.selectedOrganisationId,
        selectedCompanyId: requestDTO.selectedCompanyId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AnalyticsCompanyItemPagingResultModel.toModel(dto));
  },
  /**  */
  async getAnalyticsForCompany(request: GetAnalyticsForCompanyRequestModel, config?: RequestConfig) {
    const requestDTO = GetAnalyticsForCompanyRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-analytics-for-company'] ?? 'IL/Tdg_Analytics/company/{companyId}';
    endpointPath = endpointPath.replace(/\{companyId\}/g, `${requestDTO.companyId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        selectedOrganisationId: requestDTO.selectedOrganisationId,
        selectedCompanyId: requestDTO.selectedCompanyId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AnalyticsCompanyItemPagingResultModel.toModel(dto));
  },
  /**  */
  async getAnalyticsForMyOrganisation(request: GetAnalyticsForMyOrganisationRequestModel, config?: RequestConfig) {
    const requestDTO = GetAnalyticsForMyOrganisationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-analytics-for-my-organisation'] ?? 'IL/Tdg_Analytics/organisation';
    const response = await service.get<any>(endpointPath, {
      query: {
        selectedOrganisationId: requestDTO.selectedOrganisationId,
        selectedCompanyId: requestDTO.selectedCompanyId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AnalyticsOrganisationItemPagingResultModel.toModel(dto));
  },
  /**  */
  async getAnalyticsForOrganisation(request: GetAnalyticsForOrganisationRequestModel, config?: RequestConfig) {
    const requestDTO = GetAnalyticsForOrganisationRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-analytics-for-organisation'] ?? 'IL/Tdg_Analytics/organisation/{organisationId}';
    endpointPath = endpointPath.replace(/\{organisationId\}/g, `${requestDTO.organisationId}`);
    const response = await service.get<any>(endpointPath, {
      query: {
        selectedOrganisationId: requestDTO.selectedOrganisationId,
        selectedCompanyId: requestDTO.selectedCompanyId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AnalyticsOrganisationItemPagingResultModel.toModel(dto));
  },
  /**  */
  async getAnalyticsForAdmin(request: GetAnalyticsForAdminRequestModel, config?: RequestConfig) {
    const requestDTO = GetAnalyticsForAdminRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-analytics-for-admin'] ?? 'IL/Tdg_Analytics/admin';
    const response = await service.get<any>(endpointPath, {
      query: {
        selectedOrganisationId: requestDTO.selectedOrganisationId,
        selectedCompanyId: requestDTO.selectedCompanyId,
        id: requestDTO.id,
        key: requestDTO.key,
        searchCriteria: requestDTO.searchCriteria,
        'paging.CurrentPage': requestDTO['paging.CurrentPage'],
        'paging.PageSize': requestDTO['paging.PageSize'],
        'paging.IncludeCount': requestDTO['paging.IncludeCount'],
        'paging.NoPaging': requestDTO['paging.NoPaging'],
      },
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(AnalyticsAdminItemPagingResultModel.toModel(dto));
  },
});
