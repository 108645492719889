// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import CompanyRequestSchema from './company-request-schema';
import UserRequestSchema from './user-request-schema';

const schema = {
  $id: '/post-company-request-schema',
  type: 'object',
  required: ['acceptConditions'],
  properties: {
    accessToken: {
      type: 'string',
      nullable: true,
    },
    acceptConditions: {
      type: 'boolean',
    },
    company: {
      ...CompanyRequestSchema,
    },
    user: {
      ...UserRequestSchema,
    },
  },
} as IJSONSchema;

export default schema;
