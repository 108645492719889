import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetAnalyticsForCompanyRequestModel from '../../../../../services/v2/model/get-analytics-for-company-request-model';
import GetAnalyticsForMyCompanyRequestModel from '../../../../../services/v2/model/get-analytics-for-my-company-request-model';
import GetAnalyticsForMyOrganisationRequestModel from '../../../../../services/v2/model/get-analytics-for-my-organisation-request-model';

export default class CompanyAnalyticsPage extends PageBase {
  public adminMode: boolean = false;

  public useDataSourceById: boolean = false;

  public get pageTitle() { return (this.useDataSourceById ? this.companyById.data?.data.companyName : `${Vue.$t('page.dashboard.companies.zahlen.title')}`); }

  public get companiesAnalyticsList() {
    return this.dataSource.data?.items.map((i) => {
      return {
        timeSpan: i.data.timeSpan,
        countShowDetails: i.data.analytics?.countShowDetails,
        countAddToTour: i.data.analytics?.countAddToTour,
        countRemoveFromTour: i.data.analytics?.countRemoveFromTour,
        countDownloadTour: i.data.analytics?.countDownloadTour,
      };
    }) || [];
  }

  public set companiesAnalyticsList(value) { /* Do nothing */ }

  public get dataSource() { return (this.useDataSourceById ? this.companyAnalyticsById : this.companyAnalytics); }

  public set dataSource(value) { /* Do nothing */ }

  public companyById = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanyById,
  });

  public companyAnalytics = new DataSource({
    selectCommand: async (request: GetAnalyticsForMyCompanyRequestModel, config?: RequestConfig) => {
      try {
        return await Vue.$service.v2.api.tdg_Analytics.getAnalyticsForMyCompany(request, config);
      } catch (err:any) {
        if (err && err?.data?.result?.status === 404) {
          Vue.$router.push('/tourdegwaerb/unknownpage');
          return undefined;
        }
        throw new Error(`${err?.message}`);
      }
    },
  });

  public companyAnalyticsById = new DataSource({
    selectCommand: async (request: GetAnalyticsForCompanyRequestModel, config?: RequestConfig) => {
      try {
        return await Vue.$service.v2.api.tdg_Analytics.getAnalyticsForCompany(request, config);
      } catch (err:any) {
        if (err && (err?.data?.result?.status === 404 || err?.status === 403)) {
          Vue.$router.push('/tourdegwaerb/unknownpage');
          return undefined;
        }
        throw new Error(`${err?.message}`);
      }
    },
  });

  public organisationAnalytics = new DataSource({
    selectCommand: async (request: GetAnalyticsForMyOrganisationRequestModel, config?: RequestConfig) => {
      try {
        return await Vue.$service.v2.api.tdg_Analytics.getAnalyticsForMyOrganisation(request, config);
      } catch (err:any) {
        if (err && (err?.data?.result?.status === 404 || err?.status === 403)) {
          Vue.$router.push('/tourdegwaerb/unknownpage');
          return;
        }
        throw new Error(`${err?.message}`);
      }
    },
  });

  async initialize(): Promise<void> {
    // not used
  }

  public companyColumns = [
    { value: Vue.$t('page.dashboard.companyAnalytics.table.timeSpan'), field: 'timeSpan' },
    { value: Vue.$t('page.dashboard.companyAnalytics.table.viewed'), textAlign: 'center', field: 'countShowDetails' },
    { value: Vue.$t('page.dashboard.companyAnalytics.table.tourAdded'), textAlign: 'center', field: 'countAddToTour' },
    { value: Vue.$t('page.dashboard.companyAnalytics.table.tourRemoved'), textAlign: 'center', field: 'countRemoveFromTour' },
    { value: Vue.$t('page.dashboard.companyAnalytics.table.tourDownloaded'), textAlign: 'center', field: 'countDownloadTour' },
  ];
}
