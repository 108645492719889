import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import personalDetailsVueSchema from './edit.vue.schema';
import GetFileByExternalIdRequestModel from '../../../../../services/v2/model/get-file-by-external-id-request-model';
import UploadDocumentFileRequestModel from '../../../../../services/v2/model/upload-document-file-request-model';
import PutOrganisationRequestModel from '../../../../../services/v2/model/put-organisation-request-model';
import PutOrganisationRequestDTO from '../../../../../services/v2/_generated/dto/put-organisation-request-dto';

export default class EditOrganisationsPage extends PageBase {
  public get organisation():PutOrganisationRequestModel { return this.organisationsDataSource?.data?.data ?? new PutOrganisationRequestModel(); }

  public error?: Error | string = undefined;

  public organisationId: number | undefined = undefined;

  public adminMode: boolean = false;

  public logoDocument: undefined | File[];

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  public organisationsDataSourceAdmin = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisation,
    updateCommand: Vue.$service.v2.api.tdg_Organisations.putOrganisation,
  });

  public organisationsDataSourceMy = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisationForUser,
    updateCommand: Vue.$service.v2.api.tdg_Organisations.putOrganisation,
  });

  private organisationsDataSource?: typeof this.organisationsDataSourceAdmin | typeof this.organisationsDataSourceMy = undefined;

  async initialize(): Promise<void> {
    if (this.adminMode && !Number.isNaN(this.organisationId)) {
      this.organisationsDataSourceAdmin.filter.id = this.organisationId;
      this.organisationsDataSource = this.organisationsDataSourceAdmin;
    } else {
      this.organisationsDataSource = this.organisationsDataSourceMy;
    }
    await this.organisationsDataSource.select();
    if (this.organisation.logoDocumentExternalId) {
      const requestDocument = new GetFileByExternalIdRequestModel();
      requestDocument.externalId = this.organisation.logoDocumentExternalId;
      const document = await Vue.$service.v2.api.tdg_Documents.getTdgDocumentByExternalId(requestDocument);
      try {
        const fileContent = await Vue.$service.v2.api.tdg_Documents.downloadDocumentFile(requestDocument);
        const file = new File([fileContent.data], document.data.originalFileName || 'unknown Filename', { type: document.data.contentType });
        Vue.set(this, 'logoDocument', [file]);
      } catch (error) {
        Vue.set(this, 'logoDocument', []);
      }
    }
  }

  public editOrganisations = async () => {
    const valid = this.organisation.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      const updateModel = new PutOrganisationRequestModel(<DeepPartial<PutOrganisationRequestDTO>> this.organisation);
      const logoFiles = this.logoDocument ?? [];
      if (logoFiles.length > 0) {
        const request = new UploadDocumentFileRequestModel();
        request.uploadFile = this.logoDocument?.[0];
        const documentId = await Vue.$service.v2.api.tdg_Documents.uploadDocumentFile(request);
        updateModel.logoDocumentExternalId = documentId.data;
      } else {
        updateModel.logoDocumentExternalId = undefined;
      }
      try {
        await this.organisationsDataSource?.update(updateModel);
        Vue.$router.back();
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
      }
    }
  };
}
