<template>
  <div class="pa-6">
    <p-progress-linear v-if="isLoading" :indeterminate="true" color="base" height="10">
    </p-progress-linear>
    <p-container v-if="!isLoading">
      <h2 class="mb-4">
        <p-button tertiary @click="$router.back()">
          <p-icon>keyboard_backspace</p-icon>
        </p-button>
        {{ page.organisation.organisationName }}
      </h2>
      <p-row>
        <p-col>
          <p-row>
            <p-card>
              <p-row column>
                <p-col>
                  <p-form :model="page.organisation">
                    <h3 class="mb-2">
                      {{ $t('page.createOrganisation.title.organisationAdress') }}
                    </h3>
                    <p-text-field v-model="page.organisation.organisationName" :label="$t('page.createOrganisation.label.organisationName')" required></p-text-field>
                    <p-row>
                      <p-col xs12 sm10>
                        <p-text-field v-model="page.organisation.organisationStreet" :label="$t('page.createCompany.label.companyStreet')" required></p-text-field>
                      </p-col>
                      <p-col xs12 sm2>
                        <p-text-field v-model="page.organisation.organisationHouseNumber" :label="$t('page.createCompany.label.companyHouseNumber')"></p-text-field>
                      </p-col>
                    </p-row>
                    <p-row>
                      <p-col xs12 sm3>
                        <p-text-field v-model="page.organisation.organisationZIP" :label="$t('page.createCompany.label.companyZIP')" required></p-text-field>
                      </p-col>
                      <p-col xs12 sm9>
                        <p-text-field v-model="page.organisation.organisationCity" :label="$t('page.createCompany.label.companyCity')" required></p-text-field><br>
                      </p-col>
                    </p-row>
                    <p-file-drop
                      v-model="page.logoDocument"
                      :label="$t('page.createOrganisation.label.LogoDocumentId')"
                      max="7"
                      accept="image/*"
                      single
                    ></p-file-drop>
                    <p-number-field v-model="page.organisation.membersCount" :label="$t('page.createOrganisation.label.membersCount')" required></p-number-field>
                    <h3 class="mb-2">
                      {{ $t('page.createOrganisation.title.organisationContactDetails') }}
                    </h3>
                    <p-select v-model="page.organisation.organisationContactSalutation" :label="$t('page.createOrganisation.label.salutation')" :items="page.genderOptions()" required></p-select>
                    <p-text-field v-model="page.organisation.organisationContactFirstName" :label="$t('page.createOrganisation.label.firstName')" required></p-text-field>
                    <p-text-field v-model="page.organisation.organisationContactLastName" :label="$t('page.createOrganisation.label.lastName')" required></p-text-field>
                    <p-phone-field v-model="page.organisation.organisationContactPhoneNumber" :label="$t('page.createOrganisation.label.phoneNumber')" required type="tel"></p-phone-field>
                    <p-text-field v-model="page.organisation.organisationContactEmail" :label="$t('page.createOrganisation.label.email')" required type="email"></p-text-field>
                    <!--
                    <p-row v-if="page.adminMode">
                      <p-toggle v-model="page.organisation.isActive" :label="$t('page.createOrganisation.label.isActive')" color="success"></p-toggle>
                    </p-row>
                    -->
                    <p-row v-if="page.adminMode">
                      <p-checkbox v-model="page.organisation.isSupporter" :label="$t('page.createOrganisation.label.isSupporter')"></p-checkbox>
                    </p-row>
                  </p-form>
                  <p-request-alert v-model="page.error"></p-request-alert>
                  <p-row>
                    <p-button @click="$router.back()">
                      {{ $t('core.app.cancel') }}
                    </p-button>
                    <p-button @click="page.editOrganisations()">
                      {{ $t('core.app.save') }}
                    </p-button>
                  </p-row>
                </p-col>
              </p-row>
            </p-card>
          </p-row>
        </p-col>
      </p-row>
    </p-container>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import EditOrganisationsPage from './edit.vue.model';

  export default Vue.extend({
    auth: true,
    data: () => ({
      page: new EditOrganisationsPage(),
      isLoading: false,
      search: undefined,
      selected: undefined,
    }),
    async beforeMount() {
      this.isLoading = true;
      this.page.organisationId = Number.parseInt(this.$route.params.id, 10);
      const isAdmin = this.$auth.isLoggedIn && this.$auth.isInRole('Admin', 'HostAdmin');
      this.page.adminMode = isAdmin;
      const isOrganisation = await this.$service.v2.api.tdg_Users.amIOrganisation() && (Number.isNaN(this.page.organisationId));
      const isCompany = await this.$service.v2.api.tdg_Users.amICompany() && (Number.isNaN(this.page.organisationId));
      if (this.$route.name === 'editOrganisation') {
        if (isOrganisation) {
          this.$router.push('/tourdegwaerb/organisation/dashboard');
          return;
        }
        if (isCompany) {
          this.$router.push('/tourdegwaerb/company/dashboard');
          return;
        }
        if (!isAdmin) {
          this.$auth.redirectToLogin();
          return;
        }
      } else if (this.$route.name === 'editMyOrganisation') {
        if (isCompany) {
          this.$router.push('/tourdegwaerb/company/dashboard');
          return;
        }
        if (!isOrganisation) {
          this.$auth.redirectToLogin();
          return;
        }
      } else {
        this.$router.push('/tour-de-gwaerb/il/');
        return;
      }
      this.page.initialize();
      this.isLoading = false;
    },
  });
</script>
