import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class OrganisationAnalyticsPage extends PageBase {
  public adminMode: boolean = false;

  public get dataSource() { return this.adminAnalytics; }

  public set dataSource(value) { /* Do nothing */ }

  public get adminAnalyticsList() {
    return this.adminAnalytics.data?.items.map((i) => {
      return {
        companyName: i.data.companyName,
        companyId: i.data.companyId,
        countShowDetails: i.data.analytics?.countShowDetails,
        countAddToTour: i.data.analytics?.countAddToTour,
        countRemoveFromTour: i.data.analytics?.countRemoveFromTour,
        countDownloadTour: i.data.analytics?.countDownloadTour,
      };
    }) || [];
  }

  public set adminAnalyticsList(value) { /* Do nothing */ }

  public adminAnalytics = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Analytics.getAnalyticsForAdmin,
  });

  async initialize(): Promise<void> {
    await this.adminAnalytics.select();
  }

  public companyColumns = [
    { value: Vue.$t('page.dashboard.adminAnalytics.table.companyName'), field: 'companyName' },
    { value: Vue.$t('page.dashboard.adminAnalytics.table.viewed'), textAlign: 'center', field: 'countShowDetails' },
    { value: Vue.$t('page.dashboard.adminAnalytics.table.tourAdded'), textAlign: 'center', field: 'countAddToTour' },
    { value: Vue.$t('page.dashboard.adminAnalytics.table.tourRemoved'), textAlign: 'center', field: 'countRemoveFromTour' },
    { value: Vue.$t('page.dashboard.adminAnalytics.table.tourDownloaded'), textAlign: 'center', field: 'countDownloadTour' },
    { value: '', field: 'actions' },
  ];

  async refreshCompanies() {
    try {
      await this.adminAnalytics.select();
    } catch (error) {
      Vue.$log.error(`Error refreshing companies: ${error}`);
    }
  }

  gotoDetail(id: string | number) {
    Vue.$router.push(`/_admin/analytics/${id}`);
  }
}
