// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import OrganisationRequestModel from '../../model/organisation-request-model';
import OrganisationRequestDTO from '../dto/organisation-request-dto';
import dtoSchema from '../schemas/organisation-request-schema';
import ContactRequestModel from '../../model/contact-request-model';

export default abstract class OrganisationRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<OrganisationRequestDTO>) {
    super();
    if (data) {
      this.name = data.name;
      this.logoDocumentExternalId = data.logoDocumentExternalId ?? undefined;
      this.membersCount = data.membersCount;
      this.street = data.street;
      this.houseNumber = data.houseNumber ?? undefined;
      this.zip = data.zip;
      this.city = data.city;
      this.isActive = data.isActive ?? undefined;
      this.isSupporter = data.isSupporter ?? undefined;
      this.contact = data.contact ? ContactRequestModel.toModel(data.contact) : undefined;
    }
  }
  /**
  * @minimum 1
  */
  name?: string;
  /**
  */
  logoDocumentExternalId?: string;
  /**
  * @type {int32}
  */
  membersCount?: number;
  /**
  * @minimum 1
  */
  street?: string;
  /**
  */
  houseNumber?: string;
  /**
  * @minimum 1
  */
  zip?: string;
  /**
  * @minimum 1
  */
  city?: string;
  /**
  */
  isActive?: boolean;
  /**
  */
  isSupporter?: boolean;
  /**
  */
  contact?: ContactRequestModel;

  static toModel(dto: DeepPartial<OrganisationRequestDTO>): OrganisationRequestModel;
  static toModel(dto: DeepPartial<OrganisationRequestDTO> | undefined | null): OrganisationRequestModel | undefined;
  static toModel(dto: DeepPartial<OrganisationRequestDTO> | undefined | null): OrganisationRequestModel | undefined {
    return dto ? new OrganisationRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<OrganisationRequestModel> | OrganisationRequestModel): OrganisationRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      name: unwrapped.name,
      logoDocumentExternalId: unwrapped.logoDocumentExternalId,
      membersCount: unwrapped.membersCount,
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      zip: unwrapped.zip,
      city: unwrapped.city,
      isActive: unwrapped.isActive,
      isSupporter: unwrapped.isSupporter,
      contact: unwrapped.contact,
    } as OrganisationRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for OrganisationRequestModel, can not map to OrganisationRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
