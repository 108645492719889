import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import Tdg_OrganisationModel from '../../../../services/v2/model/tdg-organisation-model';

export default class OrganisationDashboardPage extends PageBase {
  public get organisation() { return this.organisationsDataSource.data?.data ?? new Tdg_OrganisationModel(); }

  private organisationsDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisationForUser,
  });

  public get organisationName() { return this.organisationsDataSource.data?.data.organisationName; }

  async initialize(): Promise<void> {
    this.organisationsDataSource.select();
  }
}
