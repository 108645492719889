<template>
  <div class="adminAnalytics-page pa-6">
    <p-progress-linear v-if="isLoading " :indeterminate="true" color="base" height="10">
    </p-progress-linear>
    <p-container v-if="!isLoading && page.adminAnalytics">
      <!-- Title -->
      <h2 class="mb-4">
        <p-button tertiary @click="$router.back()">
          <p-icon>keyboard_backspace</p-icon>
        </p-button>
        {{ $t('page.dashboard.admin.zahlen.title') }}
      </h2>
      <p-row>
        <!-- Search Field -->
        <p-col xs12 md6 lg2>
          <p-text-field v-model="page.adminAnalytics.filter.searchCriteria" clearable :label="$t('core.app.search')" context-icon="search"></p-text-field>
        </p-col>
        <p-col xs12 md6 lg2>
          <p-select
            v-model="page.adminAnalytics.filter.selectedOrganisationId"
            clearable
            :label="$t('page.companies.select.organisationName')"
            :items="organisationNameItems"
            @input="page.refreshCompanies()"
          ></p-select>
        </p-col>
      </p-row>
      <p-table
        v-model="page.adminAnalyticsList"
        :columns="page.companyColumns"
        :loading="page.adminAnalytics.isLoading"
      >
        <template #actions="{ row }">
          <p-col xs12 sm6 md10>
            <p-row dense>
              <p-col xs6>
                <p-button @click="page.gotoDetail(row.values.companyId)">
                  <p-icon>arrow_forward</p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-col>
        </template>
      </p-table>
      <p-pagination v-model="innerPagination" @input="onPaginationChange"></p-pagination>
    </p-container>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Int64LookupModel from '@/src/services/v2/model/int64-lookup-model';
  import LookupRequestModel from '@/src/services/v2/model/lookup-request-model';
  import { IDataSourcePaging } from '@glittr/frontend-core/src/core/v2/data/data-source';
  import OrganisationAnalyticsPage from './analytics.vue.model';

  export default Vue.extend({
    name: 'OrganisationAnalytics',
    auth: true,
    data: () => ({
      page: new OrganisationAnalyticsPage(),
      organisationNameItems: [] as Int64LookupModel[],
      isLoading: false,
      innerPagination: {} as IDataSourcePaging,
    }),
    computed: {
      isAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
    },
    watch: {
      'page.dataSource.paging': {
        handler() {
          this.innerPagination = this.page.dataSource.paging;
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      const req = new LookupRequestModel();
      const res = await this.$service.v2.api.tdg_Organisations.lookup(req);
      this.organisationNameItems = res.items.map((i: { data: any; }) => i.data);
      this.page.initialize();
      this.isLoading = false;
    },
    methods: {
      onPaginationChange() {
        this.$debounce(() => {
          this.page.dataSource.paging = this.innerPagination;
        }, 500, this)();
      },
    },
  });
</script>
