// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import LocationSchema from './location-schema';
import ContactRequestSchema from './contact-request-schema';

const schema = {
  $id: '/company-request-schema',
  type: 'object',
  required: ['name', 'zip', 'city', 'location'],
  properties: {
    name: {
      type: 'string',
      minLength: 1,
    },
    logoDocumentExternalId: {
      type: 'string',
      nullable: true,
    },
    description: {
      type: 'string',
      nullable: true,
    },
    website: {
      type: 'string',
      nullable: true,
    },
    employeesCount: {
      type: 'integer',
      nullable: true,
    },
    apprenticesCount: {
      type: 'integer',
      nullable: true,
    },
    apprenticeShipsCount: {
      type: 'integer',
      nullable: true,
    },
    jobs: {
      type: 'string',
      nullable: true,
    },
    street: {
      type: 'string',
      nullable: true,
    },
    houseNumber: {
      type: 'string',
      nullable: true,
    },
    zip: {
      type: 'string',
      minLength: 1,
    },
    city: {
      type: 'string',
      minLength: 1,
    },
    location: {
      ...LocationSchema,
    },
    contact: {
      ...ContactRequestSchema,
    },
    acceptConditions: {
      type: 'boolean',
    },
  },
} as IJSONSchema;

export default schema;
