<template>
  <div class="pa-6">
    <p-progress-linear v-if="isLoading" :indeterminate="true" color="base" height="10">
    </p-progress-linear>
    <p-container v-if="!isLoading">
      <h2 class="mb-4">
        <p-button tertiary @click="$router.back()">
          <p-icon>keyboard_backspace</p-icon>
        </p-button>
        {{ page.company.companyName }}
      </h2>
      <p-row>
        <p-col>
          <p-row>
            <p-card>
              <p-row column>
                <p-col>
                  <p-form :model="page.company">
                    <p-text-field v-model="page.company.accessToken" :label="$t('page.createCompany.label.externalId')" required></p-text-field>
                    <h3 class="mb-2">
                      {{ $t('page.createCompany.title.companyAdress') }}
                    </h3>
                    <p-text-field v-model="page.company.companyName" :label="$t('page.createCompany.label.companyName')" required></p-text-field>
                    <p-row>
                      <p-col xs12 sm10>
                        <p-text-field v-model="page.company.companyStreet" :label="$t('page.createCompany.label.companyStreet')" required></p-text-field>
                      </p-col>
                      <p-col xs12 sm2>
                        <p-text-field v-model="page.company.companyHouseNumber" :label="$t('page.createCompany.label.companyHouseNumber')"></p-text-field>
                      </p-col>
                    </p-row>
                    <p-row>
                      <p-col xs12 sm3>
                        <p-text-field v-model="page.company.companyZIP" :label="$t('page.createCompany.label.companyZIP')" required></p-text-field>
                      </p-col>
                      <p-col xs12 sm9>
                        <p-text-field v-model="page.company.companyCity" :label="$t('page.createCompany.label.companyCity')" required></p-text-field><br>
                      </p-col>
                    </p-row>
                    <p-file-drop
                      v-model="page.logoDocument"
                      :label="$t('page.createCompany.label.LogoDocumentId')"
                      max="7"
                      accept="image/*"
                      single
                    ></p-file-drop>
                    <p-text-field v-model="page.company.website" :label="$t('page.createCompany.label.website')"></p-text-field>
                    <p-textarea v-model="page.company.description" :label="$t('page.createCompany.label.description')"></p-textarea>
                    <p-number-field v-model="page.company.employeesCount" :label="$t('page.createCompany.label.employeesCount')"></p-number-field>
                    <p-number-field v-model="page.company.apprenticesCount" :label="$t('page.createCompany.label.apprenticesCount')"></p-number-field>
                    <p-number-field v-model="page.company.apprenticeShipsCount" :label="$t('page.createCompany.label.apprenticeShipsCount')"></p-number-field>
                    <p-textarea v-model="page.company.jobs" :messages="$t('page.createCompany.label.jobs.message')" :label="$t('page.createCompany.label.jobs')"></p-textarea>
                    <p-location
                      v-if="page.company.companyLocation && page.company.companyLocation.latitude && page.company.companyLocation.longitude"
                      :default-latitude="page.company.companyLocation.latitude"
                      :default-longitude="page.company.companyLocation.longitude"
                      @latitudeChange="page.company.companyLocation.latitude = $event"
                      @longitudeChange="page.company.companyLocation.longitude = $event"
                    ></p-location>
                    <h3 class="mb-2">
                      {{ $t('page.createCompany.titel.companyContactDetails') }}
                    </h3>
                    <p-select v-model="page.company.companyContactSalutation" :label="$t('page.createCompany.label.companyContactSalutation')" :items="page.genderOptions()" required></p-select>
                    <p-text-field v-model="page.company.companyContactFirstName" :label="$t('page.createCompany.label.companyContactFirstName')" required></p-text-field>
                    <p-text-field v-model="page.company.companyContactLastName" :label="$t('page.createCompany.label.companyContactLastName')" required></p-text-field>
                    <p-phone-field v-model="page.company.companyContactPhoneNumber" :label="$t('page.createCompany.label.companyContactPhoneNumber')" required></p-phone-field>
                    <p-text-field v-model="page.company.companyContactEmail" :label="$t('page.createCompany.label.companyContactEmail')" required type="email"></p-text-field>
                  </p-form>
                  <p-request-alert v-model="page.error"></p-request-alert>
                  <p-row>
                    <p-button @click="$router.back()">
                      {{ $t('core.app.cancel') }}
                    </p-button>
                    <p-button @click="page.editCompanies()">
                      {{ $t('core.app.save') }}
                    </p-button>
                  </p-row>
                </p-col>
              </p-row>
            </p-card>
          </p-row>
        </p-col>
      </p-row>
    </p-container>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import EditCompaniesPage from './edit.vue.model';

  export default Vue.extend({
    auth: true,
    data: () => ({
      page: new EditCompaniesPage(),
      isLoading: false,
      search: undefined,
      selected: undefined,
      latitude: 0 as Number,
      longitude: 0 as Number,
    }),
    computed: {
      companyLocationLat() {
        return this.page.company.companyLocation?.latitude ?? 47.1174765953623;
      },
      companyLocationLng() {
        return this.page.company.companyLocation?.longitude ?? 9.149508327579966;
      },
      isAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
    },
    async beforeMount() {
      this.isLoading = true;
      this.page.companyId = Number.parseInt(this.$route.params.id, 10);
      const isAdmin = this.$auth.isLoggedIn && this.$auth.isInRole('Admin', 'HostAdmin');
      this.page.adminMode = isAdmin;
      const isCompany = await this.$service.v2.api.tdg_Users.amICompany() && (Number.isNaN(this.page.companyId));
      const isOrganisation = await this.$service.v2.api.tdg_Users.amIOrganisation() && (Number.isNaN(this.page.companyId));
      if (this.$route.name === 'editCompany') {
        if (isOrganisation) {
          this.$router.push('/tourdegwaerb/organisation/dashboard');
          return;
        }
        if (isCompany) {
          this.$router.push('/tourdegwaerb/company/dashboard');
          return;
        }
        if (!isAdmin) {
          this.$auth.redirectToLogin();
          return;
        }
      } else if (this.$route.name === 'editMyCompany') {
        if (isOrganisation) {
          this.$router.push('/tourdegwaerb/organisation/dashboard');
          return;
        }
        if (!isCompany) {
          this.$auth.redirectToLogin();
          return;
        }
      } else {
        this.$router.push('/tour-de-gwaerb/il/');
        return;
      }
      this.page.initialize();
      this.isLoading = false;
    },
  });
</script>
