import coreRoutes from '@glittr/frontend-core/src/routes';

export default {
  mode: 'history',
  base: process.env!.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      redirect: () => ({ path: '/tour-de-gwaerb/il' }),
    },
    ...coreRoutes,
    {
      path: '/_admin/organisations',
      name: 'organisations',
      component: () => import('./views/pages/_admin/organisations/organisations.vue'),
    },
    {
      path: '/_admin/organisations/edit/:id',
      name: 'editOrganisation',
      component: () => import('./views/pages/app/organisations/edit/edit.vue'),
    },
    {
      path: '/_admin/companies',
      name: 'companies',
      component: () => import('./views/pages/_admin/companies/companies.vue'),
    },
    {
      path: '/_admin/companies/edit/:id',
      name: 'editCompany',
      component: () => import('./views/pages/app/companies/edit/edit.vue'),
    },
    {
      path: '/_admin/analytics',
      name: 'adminAnalytics',
      component: () => import('./views/pages/_admin/analytics/analytics.vue'),
    },
    {
      path: '/_admin/analytics/:id',
      name: 'allMembersCompanyAnalytics',
      component: () => import('./views/pages/app/companies/analytics/analytics.vue'),
    },
    {
      path: '/:tenant/:workspace',
      name: 'dashboard',
      component: () => import('./views/pages/app/dashboard.vue'),
    },
    {
      path: '/_widget/companies/registration',
      name: 'companyRegistration',
      component: () => import('./views/pages/_widget/companies/registration.vue'),
    },
    {
      path: '/_admin/testing/widget/companies/registration',
      name: 'companyRegistrationTesting',
      component: () => import('./views/pages/_admin/testing/widget/companies/companyRegistrationTesting.vue'),
    },
    {
      path: '/_widget/organisations/registration',
      name: 'organisationRegistration',
      component: () => import('./views/pages/_widget/organisations/registration.vue'),
    },
    {
      path: '/_admin/testing/widget/organisations/registration',
      name: 'organisationRegistrationTesting',
      component: () => import('./views/pages/_admin/testing/widget/organisations/organisationRegistrationTesting.vue'),
    },
    {
      path: '/tourdegwaerb/organisation/dashboard',
      name: 'organisationDashboard',
      component: () => import('./views/pages/app/organisations/dashboard.vue'),
    },
    {
      path: '/tourdegwaerb/organisation/edit',
      name: 'editMyOrganisation',
      component: () => import('./views/pages/app/organisations/edit/edit.vue'),
    },
    {
      path: '/tourdegwaerb/organisation/members',
      name: 'seeMembers',
      component: () => import('./views/pages/app/organisations/seeMembers/seeMembers.vue'),
    },
    {
      path: '/tourdegwaerb/company/dashboard',
      name: 'companyDashboard',
      component: () => import('./views/pages/app/companies/dashboard.vue'),
    },
    {
      path: '/tourdegwaerb/company/edit',
      name: 'editMyCompany',
      component: () => import('./views/pages/app/companies/edit/edit.vue'),
    },
    {
      path: '/tourdegwaerb/company/analytics',
      name: 'myCompanyAnalytics',
      component: () => import('./views/pages/app/companies/analytics/analytics.vue'),
    },
    {
      path: '/tourdegwaerb/company/analytics/:id',
      name: 'myMembersCompanyAnalytics',
      component: () => import('./views/pages/app/companies/analytics/analytics.vue'),
    },
    {
      path: '/tourdegwaerb/organisation/analytics',
      name: 'myOrganisationAnalytics',
      component: () => import('./views/pages/app/organisations/analytics/analytics.vue'),
    },

    {
      path: '/_widget/tdgMap/map',
      name: 'tdgMap',
      component: () => import('./views/pages/_widget/map/map.vue'),
    },
    {
      path: '/_admin/testing/widget/tgdMap/map',
      name: 'tdgMapTesting',
      component: () => import('./views/pages/_admin/testing/widget/map/tdgMapTesting.vue'),
    },
  ],
};
