// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import AnalyticsCompanyItemModel from '../../model/analytics-company-item-model';
import AnalyticsCompanyItemDTO from '../dto/analytics-company-item-dto';
import dtoSchema from '../schemas/analytics-company-item-schema';
import AnalyticsItemModel from '../../model/analytics-item-model';

export default abstract class AnalyticsCompanyItemModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<AnalyticsCompanyItemDTO>) {
    super();
    if (data) {
      this.timeSpan = data.timeSpan ?? undefined;
      this.analytics = data.analytics ? AnalyticsItemModel.toModel(data.analytics) : undefined;
    }
  }
  /**
  */
  timeSpan?: string;
  /**
  */
  analytics?: AnalyticsItemModel;

  static toModel(dto: DeepPartial<AnalyticsCompanyItemDTO>): AnalyticsCompanyItemModel;
  static toModel(dto: DeepPartial<AnalyticsCompanyItemDTO> | undefined | null): AnalyticsCompanyItemModel | undefined;
  static toModel(dto: DeepPartial<AnalyticsCompanyItemDTO> | undefined | null): AnalyticsCompanyItemModel | undefined {
    return dto ? new AnalyticsCompanyItemModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<AnalyticsCompanyItemModel> | AnalyticsCompanyItemModel): AnalyticsCompanyItemDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      timeSpan: unwrapped.timeSpan,
      analytics: unwrapped.analytics,
    } as AnalyticsCompanyItemDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for AnalyticsCompanyItemModel, can not map to AnalyticsCompanyItemDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
