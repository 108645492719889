// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetMapItemsAsyncRequestModel from '../../model/get-map-items-async-request-model';
import Tdg_MapItemListResultModel from '../../model/tdg-map-item-list-result-model';

export default (service: Servicelayer) => ({
  /**  */
  async getMapItemsAsync(request: GetMapItemsAsyncRequestModel, config?: RequestConfig) {
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-map-items-async'] ?? 'widgets/Tdg_MapItems';
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(Tdg_MapItemListResultModel.toModel(dto));
  },
});
