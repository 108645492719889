// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostAnalyticsRequestModel from '../../model/post-analytics-request-model';
import PostAnalyticsRequestDTO from '../dto/post-analytics-request-dto';
import dtoSchema from '../schemas/post-analytics-request-schema';

export default abstract class PostAnalyticsRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostAnalyticsRequestDTO>) {
    super();
    if (data) {
      this.companyId = data.companyId;
      this.analyticEventTypeId = data.analyticEventTypeId;
      this.clientIdentifier = data.clientIdentifier;
    }
  }
  /**
  * @type {int64}
  */
  companyId?: number;
  /**
  * @minimum 1
  */
  analyticEventTypeId?: string;
  /**
  * @minimum 1
  */
  clientIdentifier?: string;

  static toModel(dto: DeepPartial<PostAnalyticsRequestDTO>): PostAnalyticsRequestModel;
  static toModel(dto: DeepPartial<PostAnalyticsRequestDTO> | undefined | null): PostAnalyticsRequestModel | undefined;
  static toModel(dto: DeepPartial<PostAnalyticsRequestDTO> | undefined | null): PostAnalyticsRequestModel | undefined {
    return dto ? new PostAnalyticsRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostAnalyticsRequestModel> | PostAnalyticsRequestModel): PostAnalyticsRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      companyId: unwrapped.companyId,
      analyticEventTypeId: unwrapped.analyticEventTypeId,
      clientIdentifier: unwrapped.clientIdentifier,
    } as PostAnalyticsRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PostAnalyticsRequestModel, can not map to PostAnalyticsRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
