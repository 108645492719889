<template>
  <div class="companyDashboard-page pa-6">
    <p-progress-linear v-if="isLoading" :indeterminate="true" color="base" height="10">
    </p-progress-linear>
    <div v-if="!isLoading" class="companyDashboardWrapper">
      <h1>{{ $t("page.dashboard.companies.title") }} {{ page.companyName }}</h1>
      <div class="dashboard-cards">
        <p-row>
          <p-col xs6 sm4 md3>
            <p-card class="fill-height">
              <div class="dashboard-card">
                <h4 class="mb-2">
                  {{ $t("page.dashboard.companies.daten.title") }}
                </h4>
                <p class="caption-1">
                  {{ $t("page.dashboard.companies.daten.text") }}
                </p>
              </div>
              <p-toolbar>
                <p-row>
                  <p-button @click="$router.push(`/tourdegwaerb/company/edit`)">
                    {{ $t("page.dashboard.companies.daten.edit") }}
                  </p-button>
                </p-row>
              </p-toolbar>
            </p-card>
          </p-col>
          <p-col xs6 sm4 md3>
            <p-card class="fill-height">
              <div class="dashboard-card">
                <h4 class="mb-2">
                  {{ $t("page.dashboard.companies.zahlen.title") }}
                </h4>
                <p class="caption-1">
                  {{ $t("page.dashboard.companies.zahlen.text") }}
                </p>
              </div>
              <p-toolbar>
                <p-row>
                  <p-button @click="$router.push(`/tourdegwaerb/company/analytics`)">
                    {{ $t("page.dashboard.companies.zahlen.ansehen") }}
                  </p-button>
                </p-row>
              </p-toolbar>
            </p-card>
          </p-col>
          <p-col xs6 sm4 md3>
            <p-card class="fill-height">
              <div class="dashboard-card">
                <h4 class="mb-2">
                  {{ $t("page.dashboard.companies.support.title") }}
                </h4>
                <p>
                  gl it gmbh<br>
                  8758 Obstalden<br>
                  <a :href="supportWebsite" target="_blank" rel="noopener noreferrer">{{ supportWebsite }}</a><br>
                  <a :href="`mailto:${supportEmail}`">{{ supportEmail }}</a>
                </p>
              </div>
            </p-card>
          </p-col>
        </p-row>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import CompanyDashboardPage from './dashboard.vue.model';

  export default Vue.extend({
    auth: true,
    name: 'CompanyDashboard',
    data: () => ({
      page: new CompanyDashboardPage(),
      supportEmail: undefined as string | undefined,
      supportWebsite: undefined as string | undefined,
      isLoading: false,
    }),
    async beforeMount() {
      this.isLoading = true;
      if (await this.$service.v2.api.tdg_Users.amICompany()) {
        this.supportEmail = this.$config.values['support-email'];
        this.supportWebsite = this.$config.values['support-website'];
        this.page.initialize();
        this.isLoading = false;
      } else if (await this.$service.v2.api.tdg_Users.amIOrganisation()) {
        this.$router.push('/tourdegwaerb/organisation/dashboard');
      } else {
        this.$router.push('/TourDeGwaerb/IL/');
      }
    },
  });
</script>
