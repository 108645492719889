import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';

export default class CompanyDashboardPage extends PageBase {
  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanyForUser,
  });

  public get companyName() { return this.companiesDataSource.data?.data.companyName; }

  async initialize(): Promise<void> {
    this.companiesDataSource.select();
  }
}
