// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LookupRequestModel from '../../model/lookup-request-model';
import LookupRequestDTO from '../dto/lookup-request-dto';
import dtoSchema from '../schemas/lookup-request-schema';

export default abstract class LookupRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LookupRequestDTO>) {
    super();
  }

  static toModel(dto: DeepPartial<LookupRequestDTO>): LookupRequestModel;
  static toModel(dto: DeepPartial<LookupRequestDTO> | undefined | null): LookupRequestModel | undefined;
  static toModel(dto: DeepPartial<LookupRequestDTO> | undefined | null): LookupRequestModel | undefined {
    return dto ? new LookupRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LookupRequestModel> | LookupRequestModel): LookupRequestDTO {
    const dto = {
    } as LookupRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LookupRequestModel, can not map to LookupRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
