import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import Tdg_OrganisationModel from '../../../../../services/v2/model/tdg-organisation-model';
import personalDetailsVueSchema from '../edit/edit.vue.schema';
import GetOrganisationForUserRequestModel from '../../../../../services/v2/model/get-organisation-for-user-request-model';

export default class EditOrganisationsPage extends PageBase {
  public get companiesList() {
    return this.companiesDataSource.data?.items.map((i) => { return { ...i.data }; }) || [];
  }

  public set companiesList(value) { /* Do nothing */ }

  public get organisation() { return this.organisationsDataSource?.data?.data ?? new Tdg_OrganisationModel(); }

  public error?: Error | string = undefined;

  public organisationId: number | undefined = undefined;

  public adminMode: boolean = false;

  public logoDocument: undefined | File[];

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  public companyColumns = [
    { value: Vue.$t('page.companies.table.id'), textAlign: 'left', field: 'id' },
    { value: Vue.$t('page.companies.table.companyName'), textAlign: 'left', field: 'companyName' },
    { value: Vue.$t('page.companies.table.companyContactFirstName'), textAlign: 'left', field: 'companyContactFirstName' },
    { value: Vue.$t('page.companies.table.companyContactLastName'), textAlign: 'left', field: 'companyContactLastName' },
    { value: Vue.$t('page.companies.table.companyContactPhoneNumber'), textAlign: 'left', field: 'companyContactPhoneNumber' },
    { value: Vue.$t('page.companies.table.companyContactEmail'), textAlign: 'left', field: 'companyContactEmail' },
    { value: '', field: 'actions' },
  ];

  public organisationsDataSourceAdmin = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisationById,
  });

  public organisationsDataSourceMy = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Organisations.getOrganisation,
  });

  public companiesDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanies,
  });

  private organisationsDataSource?: typeof this.organisationsDataSourceAdmin | typeof this.organisationsDataSourceMy = undefined;

  async initialize(): Promise<void> {
    if (this.adminMode) {
      this.organisationsDataSourceAdmin.filter.id = this.organisationId;
      this.organisationsDataSource = this.organisationsDataSourceAdmin;
      await this.organisationsDataSource.select();
      this.organisationId = this.organisationsDataSource.data?.data.id;
    } else {
      this.organisationsDataSource = this.organisationsDataSourceMy;
      this.companiesDataSource.filter.selectedOrganisationId = (await Vue.$service.v2.api.tdg_Organisations.getOrganisationForUser(new GetOrganisationForUserRequestModel())).data.id;
      this.organisationId = this.companiesDataSource.filter.selectedOrganisationId;
    }
    await this.companiesDataSource.select();
  }

  public editOrganisations = async () => {
    const valid = this.organisation.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      try {
        await this.organisationsDataSource?.update(this.organisation);
        Vue.$router.back();
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
      }
    }
  };

  async refreshCompanies() {
    try {
      await this.companiesDataSource.select();
    } catch (error) {
      Vue.$log.error(`Error refreshing companies: ${error}`);
    }
  }
}
