<template>
  <div class="pa-6">
    <p-progress-linear v-if="isLoading" :indeterminate="true" color="base" height="10">
    </p-progress-linear>
    <p-container v-if="!isLoading">
      <!-- Title -->
      <h2 class="mb-4">
        <p-button tertiary @click="$router.back()">
          <p-icon>keyboard_backspace</p-icon>
        </p-button>
        {{ $t('page.dashboard.organisations.mitglieder.title') }}
      </h2>
      <p-row>
        <!-- Search Field -->
        <p-col xs12 md6 lg2>
          <p-text-field v-model="page.companiesDataSource.filter.searchCriteria" clearable :label="$t('core.app.search')" context-icon="search"></p-text-field>
        </p-col>
      </p-row>
      <p-list-view ref="myCompanyList" v-model="page.companiesList" :pagination.sync="page.companiesDataSource.paging" :columns="page.companyColumns" :loading="page.companiesDataSource.isLoading">
        <template slot="table-actions">
          <!-- Refresh Button -->
          <p-button :disabled="isLoading" @click="page.refreshCompanies()">
            <p-icon>refresh</p-icon>
          </p-button>
        </template>
      </p-list-view>
    </p-container>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import Int64LookupModel from '@/src/services/v2/model/int64-lookup-model';
  import EditOrganisationsPage from './seeMembers.vue.model';

  export default Vue.extend({
    auth: true,
    data: () => ({
      page: new EditOrganisationsPage(),
      organisationNameItems: [] as Int64LookupModel[],
      isLoading: false,
      search: undefined,
      selected: undefined,
    }),
    async beforeMount() {
      this.isLoading = true;
      const isAdmin = this.$auth.isLoggedIn && this.$auth.isInRole('Admin', 'HostAdmin');
      if (await this.$service.v2.api.tdg_Users.amIOrganisation()) {
        this.page.companiesDataSource.paging.currentPage = this.page.companiesDataSource.paging.currentPage ?? 1;
        this.page.companiesDataSource.paging.pageSize = this.page.companiesDataSource.paging.pageSize ?? 10;
        this.page.initialize();
        this.isLoading = false;
      } else if (await this.$service.v2.api.tdg_Users.amICompany()) {
        this.$router.push('/tourdegwaerb/company/dashboard');
      } else if (isAdmin) {
        this.$router.push('/_admin/companies');
      } else {
        this.$auth.redirectToLogin();
      }
    },
  });
</script>
