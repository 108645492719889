// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import CompanyRequestModel from '../../model/company-request-model';
import CompanyRequestDTO from '../dto/company-request-dto';
import dtoSchema from '../schemas/company-request-schema';
import LocationModel from '../../model/location-model';
import ContactRequestModel from '../../model/contact-request-model';

export default abstract class CompanyRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<CompanyRequestDTO>) {
    super();
    if (data) {
      this.name = data.name;
      this.logoDocumentExternalId = data.logoDocumentExternalId ?? undefined;
      this.description = data.description ?? undefined;
      this.website = data.website ?? undefined;
      this.employeesCount = data.employeesCount ?? undefined;
      this.apprenticesCount = data.apprenticesCount ?? undefined;
      this.apprenticeShipsCount = data.apprenticeShipsCount ?? undefined;
      this.jobs = data.jobs ?? undefined;
      this.street = data.street ?? undefined;
      this.houseNumber = data.houseNumber ?? undefined;
      this.zip = data.zip;
      this.city = data.city;
      this.location = LocationModel.toModel(data.location);
      this.contact = data.contact ? ContactRequestModel.toModel(data.contact) : undefined;
      this.acceptConditions = data.acceptConditions;
    }
  }
  /**
  * @minimum 1
  */
  name?: string;
  /**
  */
  logoDocumentExternalId?: string;
  /**
  */
  description?: string;
  /**
  */
  website?: string;
  /**
  * @type {int32}
  */
  employeesCount?: number;
  /**
  * @type {int32}
  */
  apprenticesCount?: number;
  /**
  * @type {int32}
  */
  apprenticeShipsCount?: number;
  /**
  */
  jobs?: string;
  /**
  */
  street?: string;
  /**
  */
  houseNumber?: string;
  /**
  * @minimum 1
  */
  zip?: string;
  /**
  * @minimum 1
  */
  city?: string;
  /**
  */
  location?: LocationModel;
  /**
  */
  contact?: ContactRequestModel;
  /**
  */
  acceptConditions?: boolean;

  static toModel(dto: DeepPartial<CompanyRequestDTO>): CompanyRequestModel;
  static toModel(dto: DeepPartial<CompanyRequestDTO> | undefined | null): CompanyRequestModel | undefined;
  static toModel(dto: DeepPartial<CompanyRequestDTO> | undefined | null): CompanyRequestModel | undefined {
    return dto ? new CompanyRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<CompanyRequestModel> | CompanyRequestModel): CompanyRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      name: unwrapped.name,
      logoDocumentExternalId: unwrapped.logoDocumentExternalId,
      description: unwrapped.description,
      website: unwrapped.website,
      employeesCount: unwrapped.employeesCount,
      apprenticesCount: unwrapped.apprenticesCount,
      apprenticeShipsCount: unwrapped.apprenticeShipsCount,
      jobs: unwrapped.jobs,
      street: unwrapped.street,
      houseNumber: unwrapped.houseNumber,
      zip: unwrapped.zip,
      city: unwrapped.city,
      location: unwrapped.location,
      contact: unwrapped.contact,
      acceptConditions: unwrapped.acceptConditions,
    } as CompanyRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for CompanyRequestModel, can not map to CompanyRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
