<template>
  <div class="companyAnalytics-page pa-6">
    <p-progress-linear v-if="isLoading && page.dataSource.isLoading" :indeterminate="true" color="base" height="10">
    </p-progress-linear>
    <p-container v-if="!isLoading && !page.dataSource.isLoading">
      <!-- Title -->
      <h2 class="mb-4">
        <p-button tertiary @click="$router.back()">
          <p-icon>keyboard_backspace</p-icon>
        </p-button>
        {{ page.pageTitle }}
      </h2>
      <p-table
        v-model="page.companiesAnalyticsList"
        :columns="page.companyColumns"
        :loading="page.dataSource.isLoading"
      ></p-table>
      <p-pagination v-model="innerPagination" @input="onPaginationChange"></p-pagination>
    </p-container>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { IDataSourcePaging } from '@glittr/frontend-core/src/core/v2/data/data-source';
  import CompanyAnalyticsPage from './analytics.vue.model';

  export default Vue.extend({
    name: 'CompanyAnalytics',
    auth: true,
    data: () => ({
      page: new CompanyAnalyticsPage(),
      isLoading: false,
      innerPagination: {} as IDataSourcePaging,
    }),
    computed: {
      isAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
    },
    watch: {
      'page.dataSource.paging': {
        handler() {
          this.innerPagination = this.page.dataSource.paging;
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      const companyId = Number.parseInt(this.$route.params.id, 10);
      if (this.$route.name === 'myMembersCompanyAnalytics') {
        this.page.useDataSourceById = true;
        this.page.companyAnalyticsById.filter.companyId = companyId;
        this.page.companyById.filter.id = companyId;
        await this.page.companyById.select();
        await this.page.companyAnalyticsById.select();
      } else if (this.$route.name === 'myCompanyAnalytics') {
        await this.page.companyAnalytics.select();
      } else if (this.$route.name === 'allMembersCompanyAnalytics') {
        this.page.useDataSourceById = true;
        this.page.companyAnalyticsById.filter.companyId = companyId;
        this.page.companyById.filter.id = companyId;
        await this.page.companyById.select();
        await this.page.companyAnalyticsById.select();
      }
      this.page.initialize();
      this.isLoading = false;
    },
    methods: {
      onPaginationChange() {
        this.$debounce(() => {
          this.page.dataSource.paging = this.innerPagination;
        }, 500, this)();
      },
    },
  });
</script>
