// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';

const schema = {
  $id: '/analytics-item-schema',
  type: 'object',
  required: ['countShowDetails', 'countAddToTour', 'countRemoveFromTour', 'countDownloadTour'],
  properties: {
    countShowDetails: {
      type: 'integer',
    },
    countAddToTour: {
      type: 'integer',
    },
    countRemoveFromTour: {
      type: 'integer',
    },
    countDownloadTour: {
      type: 'integer',
    },
  },
} as IJSONSchema;

export default schema;
