import queryBuilder from '@glittr/frontend-core/src/plugins/validation/queryBuilder';
import validationPatterns from '@glittr/frontend-core/src/plugins/validation/validationPatterns';

const personalDetailsVueSchema = {
  ...queryBuilder.properties({
    accessToken: { type: 'string', required: true },
    companyName: { type: 'string', required: true, maxLength: 150 },
    companyStreet: { type: 'string', required: true, maxLength: 150 },
    companyHouseNumber: { type: 'string', maxLength: 10 },
    companyZIP: {
      type: 'string', required: true, minLength: 1, maxLength: 10,
    },
    companyCity: { type: 'string', required: true, maxLength: 150 },
    website: { type: 'string', maxLength: 255 },
    description: { type: 'string', maxLength: 4000 },
    employeesCount: { type: 'number', maximum: 2147483647 },
    apprenticesCount: { type: 'number', maximum: 2147483647 },
    apprenticeShipsCount: { type: 'number', maximum: 2147483647 },
    jobs: { type: 'string', maxLength: 500 },
    companyContactSalutation: { type: 'integer', required: true },
    companyContactFirstName: { type: 'string', required: true, maxLength: 100 },
    companyContactLastName: { type: 'string', required: true, maxLength: 100 },
    companyContactPhoneNumber: {
      type: 'string', pattern: validationPatterns.phone, required: true, maxLength: 20,
    },
    companyContactEmail: {
      type: 'string', pattern: validationPatterns.email, required: true, maxLength: 255,
    },
    companyLocation: {
      type: 'object',
      required: true,
      properties: {
        longitude: { type: 'number', required: true, maxLength: 19 },
        latitude: { type: 'number', required: true, maxLength: 19 },
      },
    },
  }),
};

export default personalDetailsVueSchema;
