// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import PostPDFRequestModel from '../../model/post-pdf-request-model';

export default (service: Servicelayer) => ({
  /**  */
  async postPDF(request: PostPDFRequestModel, config?: RequestConfig) {
    const requestDTO = PostPDFRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-post-pdf'] ?? 'widgets/Tdg_Tour';
    const response = await service.post<any>(endpointPath, {
      responseType: 'blob',
      body: {
        items: requestDTO.items,
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
