// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import { IJSONSchema } from '@glittr/frontend-core/src/plugins/validation/IJSONSchema';
import AnalyticsItemSchema from './analytics-item-schema';

const schema = {
  $id: '/analytics-admin-item-schema',
  type: 'object',
  required: [],
  nullable: true,
  properties: {
    companyId: {
      type: 'integer',
      nullable: true,
    },
    companyName: {
      type: 'string',
      nullable: true,
    },
    analytics: {
      ...AnalyticsItemSchema,
    },
    organisationId: {
      type: 'integer',
      nullable: true,
    },
  },
} as IJSONSchema;

export default schema;
