// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import Tdg_CompanyListModel from '../../model/tdg-company-list-model';
import Tdg_CompanyListDTO from '../dto/tdg-company-list-dto';
import dtoSchema from '../schemas/tdg-company-list-schema';
import ResourceLinkModel from '../../model/resource-link-model';
import LocationModel from '../../model/location-model';

export default abstract class Tdg_CompanyListModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<Tdg_CompanyListDTO>) {
    super();
    if (data) {
      this.id = data.id;
      this.links = data.links?.map((i) => ResourceLinkModel.toModel(i)) ?? [];
      this.creatorId = data.creatorId;
      this.creatorName = data.creatorName ?? undefined;
      this.created = data.created;
      this.modifierId = data.modifierId ?? undefined;
      this.modifierName = data.modifierName ?? undefined;
      this.modified = data.modified ?? undefined;
      this.externalId = data.externalId;
      this.companyName = data.companyName;
      this.organisationId = data.organisationId ?? undefined;
      this.organisationName = data.organisationName ?? undefined;
      this.logoDocumentId = data.logoDocumentId ?? undefined;
      this.logoDocumentExternalId = data.logoDocumentExternalId ?? undefined;
      this.description = data.description ?? undefined;
      this.website = data.website ?? undefined;
      this.employeesCount = data.employeesCount ?? undefined;
      this.apprenticesCount = data.apprenticesCount ?? undefined;
      this.apprenticeShipsCount = data.apprenticeShipsCount ?? undefined;
      this.jobs = data.jobs ?? undefined;
      this.companyContactSalutation = data.companyContactSalutation;
      this.companyContactFirstName = data.companyContactFirstName;
      this.companyContactLastName = data.companyContactLastName;
      this.companyContactPhoneNumber = data.companyContactPhoneNumber;
      this.companyContactEmail = data.companyContactEmail;
      this.companyStreet = data.companyStreet;
      this.companyHouseNumber = data.companyHouseNumber ?? undefined;
      this.companyZIP = data.companyZIP;
      this.companyCity = data.companyCity;
      this.companyLocation = LocationModel.toModel(data.companyLocation);
      this.acceptConditions = data.acceptConditions;
    }
  }
  /**
  * @type {int64}
  */
  id?: number;
  /**
  */
  links?: ResourceLinkModel[];
  /**
  * @type {int64}
  */
  creatorId?: number;
  /**
  */
  creatorName?: string;
  /**
  * @type {date-time}
  */
  created?: string;
  /**
  * @type {int64}
  */
  modifierId?: number;
  /**
  */
  modifierName?: string;
  /**
  * @type {date-time}
  */
  modified?: string;
  /**
  * @type {uuid}
  */
  externalId?: string;
  /**
  * @minimum 1
  */
  companyName?: string;
  /**
  */
  organisationId?: string;
  /**
  */
  organisationName?: string;
  /**
  * @type {int64}
  */
  logoDocumentId?: number;
  /**
  */
  logoDocumentExternalId?: string;
  /**
  */
  description?: string;
  /**
  */
  website?: string;
  /**
  * @type {int32}
  */
  employeesCount?: number;
  /**
  * @type {int32}
  */
  apprenticesCount?: number;
  /**
  * @type {int32}
  */
  apprenticeShipsCount?: number;
  /**
  */
  jobs?: string;
  /**
  * @type {int32}
  */
  companyContactSalutation?: 0 | 1 | 2; // SalutationDTO
  /**
  * @minimum 1
  */
  companyContactFirstName?: string;
  /**
  * @minimum 1
  */
  companyContactLastName?: string;
  /**
  * @minimum 1
  */
  companyContactPhoneNumber?: string;
  /**
  * @minimum 1
  */
  companyContactEmail?: string;
  /**
  * @minimum 1
  */
  companyStreet?: string;
  /**
  */
  companyHouseNumber?: string;
  /**
  * @minimum 1
  */
  companyZIP?: string;
  /**
  * @minimum 1
  */
  companyCity?: string;
  /**
  */
  companyLocation?: LocationModel;
  /**
  */
  acceptConditions?: boolean;

  static toModel(dto: DeepPartial<Tdg_CompanyListDTO>): Tdg_CompanyListModel;
  static toModel(dto: DeepPartial<Tdg_CompanyListDTO> | undefined | null): Tdg_CompanyListModel | undefined;
  static toModel(dto: DeepPartial<Tdg_CompanyListDTO> | undefined | null): Tdg_CompanyListModel | undefined {
    return dto ? new Tdg_CompanyListModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<Tdg_CompanyListModel> | Tdg_CompanyListModel): Tdg_CompanyListDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      id: unwrapped.id,
      links: unwrapped.links,
      creatorId: unwrapped.creatorId,
      creatorName: unwrapped.creatorName,
      created: unwrapped.created,
      modifierId: unwrapped.modifierId,
      modifierName: unwrapped.modifierName,
      modified: unwrapped.modified,
      externalId: unwrapped.externalId,
      companyName: unwrapped.companyName,
      organisationId: unwrapped.organisationId,
      organisationName: unwrapped.organisationName,
      logoDocumentId: unwrapped.logoDocumentId,
      logoDocumentExternalId: unwrapped.logoDocumentExternalId,
      description: unwrapped.description,
      website: unwrapped.website,
      employeesCount: unwrapped.employeesCount,
      apprenticesCount: unwrapped.apprenticesCount,
      apprenticeShipsCount: unwrapped.apprenticeShipsCount,
      jobs: unwrapped.jobs,
      companyContactSalutation: unwrapped.companyContactSalutation,
      companyContactFirstName: unwrapped.companyContactFirstName,
      companyContactLastName: unwrapped.companyContactLastName,
      companyContactPhoneNumber: unwrapped.companyContactPhoneNumber,
      companyContactEmail: unwrapped.companyContactEmail,
      companyStreet: unwrapped.companyStreet,
      companyHouseNumber: unwrapped.companyHouseNumber,
      companyZIP: unwrapped.companyZIP,
      companyCity: unwrapped.companyCity,
      companyLocation: unwrapped.companyLocation,
      acceptConditions: unwrapped.acceptConditions,
    } as Tdg_CompanyListDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for Tdg_CompanyListModel, can not map to Tdg_CompanyListDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
