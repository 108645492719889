// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import PostOrganisationRequestModel from '../../model/post-organisation-request-model';
import PostOrganisationRequestDTO from '../dto/post-organisation-request-dto';
import dtoSchema from '../schemas/post-organisation-request-schema';
import OrganisationRequestModel from '../../model/organisation-request-model';
import UserRequestModel from '../../model/user-request-model';

export default abstract class PostOrganisationRequestModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<PostOrganisationRequestDTO>) {
    super();
    if (data) {
      this.acceptConditions = data.acceptConditions;
      this.organisation = data.organisation ? OrganisationRequestModel.toModel(data.organisation) : undefined;
      this.user = data.user ? UserRequestModel.toModel(data.user) : undefined;
    }
  }
  /**
  */
  acceptConditions?: boolean;
  /**
  */
  organisation?: OrganisationRequestModel;
  /**
  */
  user?: UserRequestModel;

  static toModel(dto: DeepPartial<PostOrganisationRequestDTO>): PostOrganisationRequestModel;
  static toModel(dto: DeepPartial<PostOrganisationRequestDTO> | undefined | null): PostOrganisationRequestModel | undefined;
  static toModel(dto: DeepPartial<PostOrganisationRequestDTO> | undefined | null): PostOrganisationRequestModel | undefined {
    return dto ? new PostOrganisationRequestModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<PostOrganisationRequestModel> | PostOrganisationRequestModel): PostOrganisationRequestDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      acceptConditions: unwrapped.acceptConditions,
      organisation: unwrapped.organisation,
      user: unwrapped.user,
    } as PostOrganisationRequestDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for PostOrganisationRequestModel, can not map to PostOrganisationRequestDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
