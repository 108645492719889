import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Vue from 'vue';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import personalDetailsVueSchema from './edit.vue.schema';
import UploadDocumentFileRequestModel from '../../../../../services/v2/model/upload-document-file-request-model';
import PutCompanyRequestModel from '../../../../../services/v2/model/put-company-request-model';
import PutCompanyRequestDTO from '../../../../../services/v2/_generated/dto/put-company-request-dto';
import GetFileByExternalIdRequestModel from '../../../../../services/v2/model/get-file-by-external-id-request-model';
import LocationModel from '../../../../../services/v2/model/location-model';

export default class EditCompaniesPage extends PageBase {
  public get company():PutCompanyRequestModel {
    if (this.companiesDataSource?.data?.data) {
      return this.companiesDataSource?.data?.data;
    }
    const newModel = new PutCompanyRequestModel();
    newModel.companyLocation = new LocationModel({ latitude: 47.1177271111, longitude: 9.1492561111 });
    return newModel;
  }

  public error?: Error | string = undefined;

  public companyId: number | undefined = undefined;

  public adminMode: boolean = false;

  public logoDocument: undefined | File[];

  public genderOptions() {
    return [
      { id: 0, caption: Vue.$t('core.address.salutationEnum3') },
      { id: 1, caption: Vue.$t('core.address.salutationEnum1') },
      { id: 2, caption: Vue.$t('core.address.salutationEnum2') },
    ];
  }

  private companiesDataSourceAdmin = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompany,
    updateCommand: Vue.$service.v2.api.tdg_Companies.putCompany,
  });

  private companiesDataSourceMy = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanyForUser,
    updateCommand: Vue.$service.v2.api.tdg_Companies.putCompany,
  });

  private companiesDataSource?: typeof this.companiesDataSourceAdmin | typeof this.companiesDataSourceMy = undefined;

  async initialize(): Promise<void> {
    if (this.adminMode && !Number.isNaN(this.companyId)) {
      this.companiesDataSourceAdmin.filter.id = this.companyId;
      this.companiesDataSource = this.companiesDataSourceAdmin;
    } else {
      this.companiesDataSource = this.companiesDataSourceMy;
    }
    await this.companiesDataSource.select();
    if (this.company.logoDocumentExternalId) {
      const requestDocument = new GetFileByExternalIdRequestModel();
      requestDocument.externalId = this.company.logoDocumentExternalId;
      const document = await Vue.$service.v2.api.tdg_Documents.getTdgDocumentByExternalId(requestDocument);
      try {
        const fileContent = await Vue.$service.v2.api.tdg_Documents.downloadDocumentFile(requestDocument);
        const file = new File([fileContent.data], document.data.originalFileName || 'unknown Filename', { type: document.data.contentType });
        Vue.set(this, 'logoDocument', [file]);
      } catch (error) {
        Vue.set(this, 'logoDocument', []);
      }
    }
    if (this.company.companyLocation === undefined) {
      this.company.companyLocation = new LocationModel();
    }
  }

  public editCompanies = async () => {
    const valid = this.company.validateWithSchema(personalDetailsVueSchema);
    if (valid) {
      const updateModel = new PutCompanyRequestModel(<DeepPartial<PutCompanyRequestDTO>> this.company);
      const logoFiles = this.logoDocument ?? [];
      if (logoFiles.length > 0) {
        const request = new UploadDocumentFileRequestModel();
        request.uploadFile = this.logoDocument?.[0];
        const documentId = await Vue.$service.v2.api.tdg_Documents.uploadDocumentFile(request);
        updateModel.logoDocumentExternalId = documentId.data;
      } else {
        updateModel.logoDocumentExternalId = undefined;
      }

      try {
        await this.companiesDataSource!.update(updateModel);
        Vue.$router.back();
      } catch (error: any) {
        Vue.$log.error(error);
        this.error = error;
      }
    }
  };
}
