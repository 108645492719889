// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

/* eslint-disable quote-props */

import DataRecord, { unwrapDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import ServiceModelBase from '@glittr/frontend-core/src/core/v2/data/service-model-base';
import DeepPartial from '@glittr/frontend-core/src/core/v2/utility-types/deep-partial';
import LocationModel from '../../model/location-model';
import LocationDTO from '../dto/location-dto';
import dtoSchema from '../schemas/location-schema';

export default abstract class LocationModelBase extends ServiceModelBase {
  constructor(data?: DeepPartial<LocationDTO>) {
    super();
    if (data) {
      this.latitude = data.latitude;
      this.longitude = data.longitude;
    }
  }
  /**
  * @type {float}
  */
  latitude?: number;
  /**
  * @type {float}
  */
  longitude?: number;

  static toModel(dto: DeepPartial<LocationDTO>): LocationModel;
  static toModel(dto: DeepPartial<LocationDTO> | undefined | null): LocationModel | undefined;
  static toModel(dto: DeepPartial<LocationDTO> | undefined | null): LocationModel | undefined {
    return dto ? new LocationModel(dto) : undefined;
  }
  static toDTO(model: DataRecord<LocationModel> | LocationModel): LocationDTO {
    const unwrapped = unwrapDataRecord(model);
    const dto = {
      latitude: unwrapped.latitude,
      longitude: unwrapped.longitude,
    } as LocationDTO;
    const errors = ServiceModelBase.validator.validate(dtoSchema, dto);
    if (errors.length > 0) {
      throw new Error(`Invalid data for LocationModel, can not map to LocationDTO: ${errors.map((e) => `${e?.propName}: ${e?.message}`).join(', ')}`);
    }
    return dto;
  }

  validate(): boolean {
    const dtoErrors = this.getValidationErrors(dtoSchema);
    const modelErrors = this.getValidationErrors(this.schema ?? {});
    this.errors = [...dtoErrors, ...modelErrors];
    return this.errors.length === 0;
  }
}
