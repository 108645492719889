<template>
  <div class="organisationAnalytics-page pa-6">
    <p-progress-linear v-if="isLoading" :indeterminate="true" color="base" height="10">
    </p-progress-linear>
    <p-container v-if="!isLoading">
      <!-- Title -->
      <h2 class="mb-4">
        <p-button tertiary @click="$router.back()">
          <p-icon>keyboard_backspace</p-icon>
        </p-button>
        {{ $t('page.dashboard.organisations.zahlen.title') }}
      </h2>
      <p-row>
        <!-- Search Field -->
        <p-col xs12 md6 lg2>
          <p-text-field v-model="page.organisationAnalytics.filter.searchCriteria" clearable :label="$t('core.app.search')" context-icon="search"></p-text-field>
        </p-col>
      </p-row>
      <p-table v-model="page.organisationsAnalyticsList" :columns="page.companyColumns">
        <template #actions="{ row }">
          <p-col xs12 sm6 md10>
            <p-row dense>
              <p-col xs6>
                <p-button @click="page.gotoDetail(row.values.companyId)">
                  <p-icon>arrow_forward</p-icon>
                </p-button>
              </p-col>
            </p-row>
          </p-col>
        </template>
      </p-table>
      <p-pagination v-model="innerPagination" @input="onPaginationChange"></p-pagination>
    </p-container>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { IDataSourcePaging } from '@glittr/frontend-core/src/core/v2/data/data-source';
  import OrganisationAnalyticsPage from './analytics.vue.model';

  export default Vue.extend({
    name: 'OrganisationAnalytics',
    auth: true,
    data: () => ({
      page: new OrganisationAnalyticsPage(),
      isLoading: false,
      innerPagination: {} as IDataSourcePaging,
    }),
    computed: {
      isAdmin(): boolean {
        let isAdmin = false;
        if (this.$auth.isLoggedIn) {
          isAdmin = this.$auth.isInRole('Admin', 'HostAdmin');
        }
        return isAdmin;
      },
    },
    watch: {
      'page.dataSource.paging': {
        handler() {
          this.innerPagination = this.page.dataSource.paging;
        },
      },
    },
    async beforeMount() {
      this.isLoading = true;
      this.page.initialize();
      this.isLoading = false;
    },
    methods: {
      onPaginationChange() {
        this.$debounce(() => {
          this.page.dataSource.paging = this.innerPagination;
        }, 500, this)();
      },
    },
  });
</script>
