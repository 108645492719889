<template>
  <div class="registrationWrapper organisation">
    <p-form v-if="!page.registrationSuccess && page.combinedModels" :model="page.combinedModels" class="registrationForm form">
      <div v-if="page.combinedModels.organisation" class="verbandDetailsWrapper">
        <h3 class="title">
          {{ $t('page.createOrganisation.title.organisationAdress') }}
        </h3>
        <p-text-field v-model="page.combinedModels.organisation.name" :label="$t('page.createOrganisation.label.organisationName')" required></p-text-field>
        <p-row class="streetWrapper">
          <p-col class="street">
            <p-text-field v-model="page.combinedModels.organisation.street" :label="$t('page.createCompany.label.companyStreet')" required></p-text-field>
          </p-col>
          <p-col class="houseNumber">
            <p-text-field v-model="page.combinedModels.organisation.houseNumber" :label="$t('page.createCompany.label.companyHouseNumber')"></p-text-field>
          </p-col>
        </p-row>
        <p-row class="cityWrapper">
          <p-col class="zip">
            <p-text-field v-model="page.combinedModels.organisation.zip" :label="$t('page.createCompany.label.companyZIP')" required></p-text-field>
          </p-col>
          <p-col class="city">
            <p-text-field v-model="page.combinedModels.organisation.city" :label="$t('page.createCompany.label.companyCity')" required></p-text-field><br>
          </p-col>
        </p-row>
        <p-file-drop
          v-model="page.logoDocuments"
          class="logo file image"
          :label="$t('page.createOrganisation.label.LogoDocumentId')"
          max="7"
          accept="image/*"
          single
        ></p-file-drop>
        <p-number-field v-model="page.combinedModels.organisation.membersCount" :label="$t('page.createOrganisation.label.membersCount')" required></p-number-field>
      </div>
      <div v-if="page.combinedModels.organisation.contact" class="kontaktDetailsWrapper">
        <h3 class="title">
          {{ $t('page.createOrganisation.title.organisationContactDetails') }}
        </h3>
        <p-select v-model="page.combinedModels.organisation.contact.salutation" :label="$t('page.createOrganisation.label.salutation')" :items="page.genderOptions()" required></p-select>
        <p-text-field v-model="page.combinedModels.organisation.contact.firstName" :label="$t('page.createOrganisation.label.firstName')" required></p-text-field>
        <p-text-field v-model="page.combinedModels.organisation.contact.lastName" :label="$t('page.createOrganisation.label.lastName')" required></p-text-field>
        <p-phone-field v-model="page.combinedModels.organisation.contact.phoneNumber" :label="$t('page.createOrganisation.label.phoneNumber')" required type="tel"></p-phone-field>
        <p-text-field v-model="page.combinedModels.organisation.contact.email" :label="$t('page.createOrganisation.label.email')" required type="email"></p-text-field>
      </div>
      <div v-if="page.combinedModels.user" class="loginWrapper">
        <h3 class="title">
          {{ $t('page.widget.title.createNewUser') }}
        </h3>
        <p-text-field v-model="page.combinedModels.user.email" :placeholder="page.combinedModels.organisation.contact.email" :label="$t('page.widget.label.userEmail')" required></p-text-field>
        <p-text-field v-model="page.combinedModels.user.password" :label="$t('page.widget.label.userPassword')" type="password" required></p-text-field>
        <p-text-field v-model="page.combinedModels.user.repeatPassword" :label="$t('page.widget.label.userSecondPassword')" type="password" required></p-text-field>
      </div>
      <div class="checkboxenWrapper">
        <p-checkbox v-model="page.combinedModels.acceptConditions" :label="$tEnum('page.createCompany.label.acceptConditions', 'no_html')" required>
          <span v-html="$tEnum('page.createCompany.label.acceptConditions', 'html')"></span>
        </p-checkbox>
      </div>
      <div v-if="page.supporterAmount" class="isSupporterWrapper">
        <h3 class="title">
          {{ $t('page.widget.title.becomeSupporter') }}
        </h3>
        <p-checkbox v-if="page.combinedModels.organisation.isSupporter" v-model="page.combinedModels.organisation.isSupporter" :label="$t('page.widget.label.becomeSupporter', $format.currency(page.supporterAmount, 'CHF'))"></p-checkbox>
      </div>
    </p-form>
    <p-row v-if="!page.registrationSuccess" class="saveButton">
      <p-button :disabled="page.isLoading" :loading="page.isLoading" @click="page.saveNewOrganisations()">
        {{ $t('core.app.save') }}
      </p-button>
    </p-row>
    <p-request-alert v-if="!page.registrationSuccess" v-model="page.convertedError"></p-request-alert>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import OrganisationRegistrationPage from './registration.vue.model';

  export default Vue.extend({
    name: 'OrganisationRegistration',
    layout: 'layout-widget',
    props: {
      supporterAmount: { default: undefined, type: Number },
    },
    data: () => ({
      page: new OrganisationRegistrationPage(),
    }),
    computed: {
      innerSupporterAmount(): number | undefined {
        if (this._.isSet(this.supporterAmount)) {
          return this.supporterAmount;
        }
        if (this.$config.values['widget-supporteramount']) {
          return this.$config.values['widget-supporteramount'];
        }
        const queries = this.$routerUtils.getQueryParams();
        return queries.supporterAmount ? +queries.supporterAmount : undefined;
      },
    },
    mounted() {
      this.page.supporterAmount = this.innerSupporterAmount;
      this.page.initialize();
    },
  });
</script>
