// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2024 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import Vue from 'vue';
import { wrapResponseWithDataRecord } from '@glittr/frontend-core/src/core/v2/data/data-record';
import { Servicelayer } from '@glittr/frontend-core/src/plugins/servicelayer';
import RequestConfig from '@glittr/frontend-core/src/plugins/servicelayer/requestConfig';
import GetTdgDocumentByExternalIdRequestModel from '../../model/get-tdg-document-by-external-id-request-model';
import UploadTdgDocumentRequestModel from '../../model/upload-tdg-document-request-model';
import DownloadTdgDocumentRequestModel from '../../model/download-tdg-document-request-model';
import GetFileByExternalIdRequestModel from '../../model/get-file-by-external-id-request-model';
import UploadDocumentFileRequestModel from '../../model/upload-document-file-request-model';
import DownloadDocumentFileRequestModel from '../../model/download-document-file-request-model';
import DocumentModel from '../../model/document-model';

export default (service: Servicelayer) => ({
  /**  */
  async getTdgDocumentByExternalId(request: GetTdgDocumentByExternalIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetTdgDocumentByExternalIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-tdg-document-by-external-id'] ?? 'widgets/Tdg_Documents/{externalId}';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${requestDTO.externalId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DocumentModel.toModel(dto));
  },
  /**  */
  async uploadTdgDocument(request: UploadTdgDocumentRequestModel, config?: RequestConfig) {
    const requestDTO = UploadTdgDocumentRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-tdg-document'] ?? 'widgets/Tdg_Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async downloadTdgDocument(request: DownloadTdgDocumentRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadTdgDocumentRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-tdg-document'] ?? 'widgets/Tdg_Documents/{externalId}/Download';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${requestDTO.externalId}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
  /**  */
  async getFileByExternalId(request: GetFileByExternalIdRequestModel, config?: RequestConfig) {
    const requestDTO = GetFileByExternalIdRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-get-file-by-external-id'] ?? 'IL/Tdg_Documents/{externalId}';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${requestDTO.externalId}`);
    const response = await service.get<any>(endpointPath, {
      ...config,
    });
    const dto = response.data.result;
    return wrapResponseWithDataRecord(DocumentModel.toModel(dto));
  },
  /**  */
  async uploadDocumentFile(request: UploadDocumentFileRequestModel, config?: RequestConfig) {
    const requestDTO = UploadDocumentFileRequestModel.toDTO(request);
    const formData = new FormData();
    formData.append('uploadFile', requestDTO.uploadFile as Blob);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-upload-document-file'] ?? 'IL/Tdg_Documents/Upload';
    const response = await service.post<any>(endpointPath, {
      body: formData,
      headers: {
      },
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(result as string | undefined);
  },
  /**  */
  async downloadDocumentFile(request: DownloadDocumentFileRequestModel, config?: RequestConfig) {
    const requestDTO = DownloadDocumentFileRequestModel.toDTO(request);
    // eslint-disable-next-line prefer-const
    let endpointPath = (Vue.$config.values as any)['endpoints-download-document-file'] ?? 'IL/Tdg_Documents/{externalId}/File';
    endpointPath = endpointPath.replace(/\{externalId\}/g, `${requestDTO.externalId}`);
    const response = await service.get<any>(endpointPath, {
      responseType: 'blob',
      ...config,
    });
    const result = response?.data?.result ?? response?.data;
    return wrapResponseWithDataRecord(new Blob([result], { type: 'application/octet-stream' }));
  },
});
