import Vue from 'vue';
import PageBase from '@glittr/frontend-core/src/core/v2/app/pageBase';
import { GoogleMapsApi } from '@glittr/frontend-core/src/plugins/map/mapProvider/googleMapsProvider';
import DataSource from '@glittr/frontend-core/src/core/v2/data/data-source';
import Tdg_CompanyDetailModel from '../../../../services/v2/model/tdg-company-detail-model';
import PostAnalyticsRequestModel from '../../../../services/v2/model/post-analytics-request-model';
import Tdg_TourItemModel from '../../../../services/v2/model/tdg-tour-item-model';

export default class TdgMapPage extends PageBase {
  public cookieValue:string | null = null;

  public showCompanyInfoDiv = false;

  public showCompanyList = true;

  public mapIsReady = false;

  public isLoadingPDF = false;

  public error?: string = undefined;

  public googleApi?: GoogleMapsApi;

  public map?: google.maps.Map;

  public searchBarRef = undefined as any;

  public searchBoxRef = undefined as google.maps.places.SearchBox | undefined;

  public showAddressSearchBox = false;

  public searchFieldContainerElementRef = {} as HTMLInputElement;

  public mapContainerElementRef = {} as HTMLElement;

  public myTourListItems: Tdg_CompanyDetailModel[] = [];

  public companyDetailDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Companies.getCompanyById,
  });

  public mapItemDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_MapItems.getMapItemsAsync,
  });

  public downloadPdfDataSource = new DataSource({
    selectCommand: Vue.$service.v2.api.tdg_Tour.postPDF,
  });

  public companyAnalyticEventDataSource = new DataSource({
    insertCommand: Vue.$service.v2.api.tdg_Analytics.postAnalytics,
  });

  public async initialize(): Promise<void> {
    if (Vue.$cookie.get('TdgClientIdentifier') === null) {
      Vue.$cookie.set('TdgClientIdentifier', crypto.randomUUID());
    }
    this.cookieValue = Vue.$cookie.get('TdgClientIdentifier');
    this.updateShowCompanyList();
    this.myTourListItems = Vue.$sessionStorage.get<Tdg_CompanyDetailModel[]>('myTourElements') ?? [];
    this.googleApi = await Vue.$map.google.initialize('core', 'maps', 'marker', 'places');
  }

  public async initializeTdgMap(buttons : HTMLElement[]) {
    this.map = new google.maps.Map(
      this.mapContainerElementRef,
      {
        mapTypeId: google.maps.MapTypeId.SATELLITE,
        disableDefaultUI: false,
        zoom: 12,
        center: { lat: 47.1177271111, lng: 9.1492561111 },
        mapId: (Vue.$config.values as any)['google-mapsmapid'],
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
          position: google.maps.ControlPosition.TOP_CENTER,
        },
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        scaleControl: true,
        streetViewControl: true,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
        fullscreenControl: true,
        fullscreenControlOptions: {
          position: google.maps.ControlPosition.LEFT_TOP,
        },
      },
    );
    for (let index = 0; index < buttons.length; index += 1) {
      const btnDiv = document.createElement('div');
      const btn = buttons[index];
      btnDiv.appendChild(btn);
      this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(btnDiv);
    }
    document.addEventListener('fullscreenchange', () => {
      const pacContainer = document.querySelector('.pac-container');
      if (!pacContainer) return;
      if (document.fullscreenElement) {
        document.fullscreenElement.appendChild(pacContainer);
      } else {
        document.body.appendChild(pacContainer);
      }
    });
    await this.mapItemDataSource.select();
    const mapItems = this.mapItemDataSource.data?.items;
    const bounds = new google.maps.LatLngBounds();
    mapItems?.forEach((i) => {
      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: new google.maps.LatLng(i.data.location!.latitude!, i.data.location!.longitude!),
        map: this.map,
        title: i.data.title,
      });

      if (marker.position) {
        const normalizedPosition = marker.position instanceof google.maps.LatLng
          ? marker.position
          : new google.maps.LatLng(marker.position);
        bounds.extend(normalizedPosition);
      }

      marker.addListener('click', async () => {
        if (i.data.id !== undefined) {
          this.showCompanyInfoBox(i.data.id);
          if (this.cookieValue != null) {
            await this.fireEvent(i.data.id, 'viewed');
          }
        }
      });
    });
    if ((mapItems?.length ?? 0) > 0) {
      this.map?.fitBounds(bounds);
    }
    this.map.addListener('tilesloaded', () => {
      if (!this.mapIsReady) {
        this.mapIsReady = true;
        this.map!.controls[google.maps.ControlPosition.TOP_RIGHT].push(document.getElementsByClassName('myTourSideDrawerWrapper')![0] as HTMLElement);
        this.map!.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(document.getElementById('searchBarRef') as HTMLElement);
        this.map!.controls[google.maps.ControlPosition.TOP_LEFT].push(document.getElementById('overlayWrapper') as HTMLElement);
      }
    });
    this.setupMapSearch(this.searchFieldContainerElementRef);
  }

  public async addItemToMyTour(id: number | undefined, element: Tdg_CompanyDetailModel) {
    const existingElement = this.myTourListItems.find((item) => item.id === id);
    if (existingElement) {
      this.error = 'Diese Firma ist bereits in der Liste.';
      return;
    }

    this.myTourListItems.push(element);
    Vue.$sessionStorage.set('myTourElements', this.myTourListItems);
    this.hideCompanyInfoBox();
    this.showCompanyList = true;
    if (this.cookieValue != null) {
      await this.fireEvent(element.id, 'tourAdded');
    }
  }

  public async fireEvent(companyId:number | undefined, eventName:string) {
    if (!companyId) return;
    await this.companyAnalyticEventDataSource.insert({
      companyId,
      analyticEventTypeId: eventName,
      clientIdentifier: this.cookieValue ?? '',
    } as PostAnalyticsRequestModel);
  }

  public async removeItemFromMyTour(id: number) {
    this.myTourListItems = this.myTourListItems.filter((item) => item.id !== id);
    Vue.$sessionStorage.set('myTourElements', this.myTourListItems);
    this.showCompanyList = true;
    if (this.cookieValue != null) {
      await this.fireEvent(id, 'tourRemoved');
    }
  }

  public async downloadPdf() {
    this.isLoadingPDF = true;
    try {
      const storedTourData = Vue.$sessionStorage.get<Tdg_CompanyDetailModel[]>('myTourElements') ?? [];
      const requestData = {
        items: storedTourData.map((company) => new Tdg_TourItemModel({
          companyId: company.id,
          orderId: company.id,
        })),
      };

      const response = await this.downloadPdfDataSource.select({
        body: requestData,
      });

      if (response && response.data) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'Meine_Tour.pdf';
        link.click();
        if (this.cookieValue != null) {
          storedTourData.forEach(async (company) => {
            await this.fireEvent(company.id, 'tourDownloaded');
          });
        }
      }
    } finally {
      this.isLoadingPDF = false;
    }
  }

  public searchForAddress() {
    this.setupMapSearch(this.searchFieldContainerElementRef);
    this.searchFieldContainerElementRef.value = '';
    this.showAddressSearchBox = true;
    this.googleApi!.core.event.addListenerOnce(this.map!, 'idle', () => {
      this.mapContainerElementRef.firstChild?.appendChild(this.searchBarRef as HTMLElement);
    });
    // TODO: set focus when openening search bar #9708
    // this.searchFieldContainerElement.focus();
  }

  public setupMapSearch(searchBoxHTMLef:HTMLInputElement) {
    this.searchBoxRef = new google.maps.places.SearchBox(searchBoxHTMLef);
    this.googleApi!.core.event.addListener(this.searchBoxRef, 'places_changed', () => {
      const searchResult = this.searchBoxRef!.getPlaces();
      if (searchResult === undefined || searchResult.length === 0) {
        return;
      }
      const searchResultLat = searchResult[0].geometry?.location?.lat();
      const searchResultLng = searchResult[0].geometry?.location?.lng();
      if (searchResultLat && searchResultLng) {
        const panPoint = new google.maps.LatLng(searchResultLat, searchResultLng);
        this.map?.panTo(panPoint);
      }
      this.showAddressSearchBox = false;
    });
  }

  public findUserPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const userLatLng = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude,
          );
          this.map?.panTo(userLatLng);
        },
        () => {},
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        },
      );
    }
  }

  public getFormattedCompanyJobs(): string[] {
    return this.companyDetailDataSource.data?.data.companyJobs?.split('\n').map((job: string) => job.trimEnd()).filter((job: string) => job.length > 0) ?? [];
  }

  public async showCompanyInfoBox(companyId: number) {
    try {
      this.companyDetailDataSource.filter.id = companyId;
      await this.companyDetailDataSource.select();
      this.showCompanyInfoDiv = true;
    } finally {
      this.scrollToTopOfInfoBox();
    }
  }

  public hideCompanyInfoBox() {
    this.showCompanyInfoDiv = false;
    this.error = undefined;
  }

  public scrollToTopOfInfoBox() {
    setTimeout(() => {
      const infoBoxWrapper = document.querySelector('.infoBoxWrapper') as HTMLElement;
      if (infoBoxWrapper) {
        infoBoxWrapper.scrollTo({
          top: 0,
        });
      }
    });
  }

  private updateShowCompanyList() {
    if (window.matchMedia('(max-width: 576px)').matches) {
      this.showCompanyList = false;
    }
  }
}
